import React from "react";
import { Link } from "react-router-dom";
// import { Link } from "react-router-dom";

const Institutional: React.FC = () => {
  return (
    <>
      <p className="text-sm text-foreground mb-4">
        LIBSENSE leverages the{" "}
        <Link to="/ecr" target="_blank" className="text-primary">
          ECR initiative
        </Link>{" "}
        to foster{" "}
        <Link
          to="https://photos.wacren.net/index.php?/category/106"
          target="_blank"
          className="text-primary"
        >
          open science principles and practices at the campus level
        </Link>
        . Some African higher education institutions (HEIs) have adopted the{" "}
        <Link
          to="https://baobab.wacren.net/records/j4j51-txk08"
          target="_blank"
          className="text-primary"
        >
          institutional open science policy template
        </Link>{" "}
        proposed by LIBSENSE. LIBSENSE also leverages the Network of Experts to
        engage and influence university leadership to create a stimulating
        environment for best research ethics and data management practices.
        LIBSENSE has documented the{" "}
        <Link
          to="/assets/pdf/LIBSENSE-Regional-Open-Science-Policy-Development-Workshop-Case-Studies_v2.pdf"
          target="_blank"
          className="text-primary"
        >
          open access/open science policy case studies in African HEIs
        </Link>
        .
      </p>
    </>
  );
};

export default Institutional;
