import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  // faSearch,
  faUniversalAccess,
  faBars,
  faTimes,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { FaTwitter } from "react-icons/fa";
import ThemeToggle from "./ThemeToggle";
import { useTheme } from "../context/ThemeContext";

const Navbar: React.FC = () => {
  // const [searchOpen, setSearchOpen] = useState(false);
  const [submenuOpen, setSubmenuOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownMediaOpen, setDropdownMediaOpen] = useState(false);
  const [dropdownAbout, setDropdownAbout] = useState(false);

  const dropdownAboutRef = useRef<HTMLLIElement>(null);
  const dropdownWhatWeDoRef = useRef<HTMLLIElement>(null);
  const dropdownMediaRef = useRef<HTMLLIElement>(null);
  const dropdownaccessibilityRef = useRef<HTMLDivElement>(null);

  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleDropdownMediaToggle = () => {
    setDropdownMediaOpen(!dropdownMediaOpen);
  };

  const handleDropdownAboutToggle = () => {
    setDropdownAbout(!dropdownAbout);
  };

  const handleSubmenuToggle = () => {
    setSubmenuOpen(!submenuOpen);
  };

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  const { theme } = useTheme();
  const img =
    theme.mode === "light"
      ? "/assets/images/libsense.png"
      : "/assets/images/logo-dark.png";

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownAboutRef.current &&
      !dropdownAboutRef.current.contains(event.target as Node)
    ) {
      setDropdownAbout(false);
    }
    if (
      dropdownWhatWeDoRef.current &&
      !dropdownWhatWeDoRef.current.contains(event.target as Node)
    ) {
      setDropdownOpen(false);
    }
    if (
      dropdownMediaRef.current &&
      !dropdownMediaRef.current.contains(event.target as Node)
    ) {
      setDropdownMediaOpen(false);
    }

    if (
      dropdownaccessibilityRef.current &&
      !dropdownaccessibilityRef.current.contains(event.target as Node)
    ) {
      setSubmenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <header className="fixed w-full h-12 bg-card text-primary-foreground z-20 shadow-sm">
      <div className="flex justify-between items-center px-8">
        <NavLink
          to="/home"
          title="/"
          aria-label="/"
          className="flex items-center"
        >
          <img
            src={img}
            style={{ width: 100, height: 70 }}
            alt="Libsense Logo"
          />
        </NavLink>
        <nav className="hidden md:flex">
          <ul className="flex space-x-4 items-center gap-2 cursor-pointer">
            <li>
              <NavLink
                to="/home"
                className={({ isActive }) =>
                  isActive
                    ? "text-primary mr-2 inline-flex rounded-md px-3 py-2 text-sm font-medium"
                    : "text-foreground hover:bg-card hover:text-foreground mr-2 inline-flex rounded-md px-3 py-2 text-sm font-medium"
                }
              >
                Home
              </NavLink>
            </li>

            <li className="relative" ref={dropdownAboutRef}>
              <div
                className="inline-flex items-center cursor-pointer"
                onClick={handleDropdownAboutToggle}
              >
                <div
                  className="text-foreground hover:bg-card hover:text-foreground inline-flex rounded-md pl-3 py-2 text-sm font-medium"
                  onClick={handleDropdownAboutToggle}
                >
                  About Us
                </div>
                <FontAwesomeIcon
                  icon={faChevronDown}
                  color="gray"
                  size="sm"
                  className={`ml-1 transition-transform ${
                    dropdownAbout ? "transform rotate-180" : ""
                  }`}
                />
              </div>

              {dropdownAbout && (
                <ul className="absolute left-0 mt-2 bg-background shadow-lg rounded-md p-4">
                  <li>
                    <NavLink
                      to="/about-us#story"
                      className={({ isActive }) =>
                        isActive
                          ? "text-primary mr-2 inline-flex rounded-md px-3 py-2 text-xs font-medium"
                          : "text-foreground hover:bg-card hover:text-foreground mr-2 inline-flex rounded-md px-3 py-2 text-xs font-medium"
                      }
                      onClick={() => {
                        setDropdownAbout(false);

                        // Check if the element with id="story" exists, then scroll to it
                        setTimeout(() => {
                          const element = document.getElementById("story");
                          if (element) {
                            element.scrollIntoView({ behavior: "smooth" });
                          }
                        }, 0);
                      }}
                    >
                      The LIBSENSE Story
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/about-us/project-initiatives"
                      className={({ isActive }) =>
                        isActive
                          ? "text-primary mr-2 inline-flex rounded-md px-3 py-2 text-xs font-medium"
                          : "text-foreground hover:bg-card hover:text-foreground mr-2 inline-flex rounded-md px-3 py-2 text-xs font-medium"
                      }
                      onClick={() => setDropdownAbout(false)}
                    >
                      Project & Initiatives
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      to="/about-us/collaborations"
                      className={({ isActive }) =>
                        isActive
                          ? "text-primary mr-2 inline-flex rounded-md px-3 py-2 text-xs font-medium"
                          : "text-foreground hover:bg-card hover:text-foreground mr-2 inline-flex rounded-md px-3 py-2 text-xs font-medium"
                      }
                      onClick={() => setDropdownAbout(false)}
                    >
                      Collaborations
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/about-us/impact"
                      className={({ isActive }) =>
                        isActive
                          ? "text-primary mr-2 inline-flex rounded-md px-3 py-2 text-xs font-medium"
                          : "text-foreground hover:bg-card hover:text-foreground mr-2 inline-flex rounded-md px-3 py-2 text-xs font-medium"
                      }
                      onClick={() => setDropdownAbout(false)}
                    >
                      Impact
                    </NavLink>
                  </li>
                  {/* <li>
                    <NavLink
                      to="/about-us/policy-observatory"
                      className={({ isActive }) =>
                        isActive
                          ? "text-primary mr-2 inline-flex rounded-md px-3 py-2 text-xs font-medium"
                          : "text-foreground hover:bg-card hover:text-foreground mr-2 inline-flex rounded-md px-3 py-2 text-xs font-medium"
                      }
                      onClick={() => setDropdownAbout(false)}
                    >
                      Policy Observatory
                    </NavLink>
                  </li> */}
                </ul>
              )}
            </li>

            <li className="relative" ref={dropdownWhatWeDoRef}>
              <div
                onClick={handleDropdownToggle}
                className="inline-flex items-center cursor-pointer"
              >
                <div
                  className="text-foreground hover:bg-card hover:text-foreground inline-flex rounded-md pl-3 py-2 text-sm font-medium"
                  onClick={handleDropdownToggle}
                >
                  What We Do
                </div>
                <FontAwesomeIcon
                  icon={faChevronDown}
                  color="gray"
                  size="sm"
                  className={`ml-1 transition-transform ${
                    dropdownOpen ? "transform rotate-180" : ""
                  }`}
                />
              </div>

              {dropdownOpen && (
                <ul className="absolute left-0 mt-2 bg-background shadow-lg rounded-md p-4">
                  <li>
                    <NavLink
                      to="/what-we-do/open-infrastructure"
                      className={({ isActive }) =>
                        isActive
                          ? "text-primary mr-2 inline-flex rounded-md px-3 py-2 text-xs font-medium"
                          : "text-foreground hover:bg-card hover:text-foreground mr-2 inline-flex rounded-md px-3 py-2 text-xs font-medium"
                      }
                      onClick={() => setDropdownOpen(false)}
                    >
                      Open Science Infrastructure
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/what-we-do/open-science-policy"
                      className={({ isActive }) =>
                        isActive
                          ? "text-primary mr-2 inline-flex rounded-md px-3 py-2 text-xs font-medium"
                          : "text-foreground hover:bg-card hover:text-foreground mr-2 inline-flex rounded-md px-3 py-2 text-xs font-medium"
                      }
                      onClick={() => setDropdownOpen(false)}
                    >
                      Open Science Policy Advocacy & Governance
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/what-we-do/capacity-building"
                      className={({ isActive }) =>
                        isActive
                          ? "text-primary mr-2 inline-flex rounded-md px-3 py-2 text-xs font-medium"
                          : "text-foreground hover:bg-card hover:text-foreground mr-2 inline-flex rounded-md px-3 py-2 text-xs font-medium"
                      }
                      onClick={() => setDropdownOpen(false)}
                    >
                      Capacity Building
                    </NavLink>
                  </li>
                </ul>
              )}
            </li>

            <li className="relative" ref={dropdownMediaRef}>
              <div
                onClick={handleDropdownMediaToggle}
                className="inline-flex items-center cursor-pointer"
              >
                <div
                  className="text-foreground hover:bg-card hover:text-foreground inline-flex rounded-md pl-3 py-2 text-sm font-medium"
                  onClick={handleDropdownMediaToggle}
                >
                  Media
                </div>
                <FontAwesomeIcon
                  icon={faChevronDown}
                  color="gray"
                  size="sm"
                  className={`ml-1 transition-transform ${
                    dropdownMediaOpen ? "transform rotate-180" : ""
                  }`}
                />
              </div>

              {dropdownMediaOpen && (
                <ul className="absolute left-0 mt-2 bg-background shadow-lg rounded-md p-4">
                  <li>
                    <NavLink
                      to="/media/news"
                      className={({ isActive }) =>
                        isActive
                          ? "text-primary mr-2 inline-flex rounded-md px-3 py-2 text-xs font-medium"
                          : "text-foreground hover:bg-card hover:text-foreground mr-2 inline-flex rounded-md px-3 py-2 text-xs font-medium"
                      }
                      onClick={() => setDropdownMediaOpen(false)}
                    >
                      News
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/media/events"
                      className={({ isActive }) =>
                        isActive
                          ? "text-primary mr-2 inline-flex rounded-md px-3 py-2 text-xs font-medium"
                          : "text-foreground hover:bg-card hover:text-foreground mr-2 inline-flex rounded-md px-3 py-2 text-xs font-medium"
                      }
                      onClick={() => setDropdownMediaOpen(false)}
                    >
                      Events
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="https://video.wacren.net/channel/LIBSENSE/595033"
                      target="_blank"
                      className={({ isActive }) =>
                        isActive
                          ? "text-primary mr-2 inline-flex rounded-md px-3 py-2 text-xs font-medium"
                          : "text-foreground hover:bg-card hover:text-foreground mr-2 inline-flex rounded-md px-3 py-2 text-xs font-medium"
                      }
                      onClick={() => setDropdownMediaOpen(false)}
                    >
                      Videos
                    </NavLink>
                  </li>
                </ul>
              )}
            </li>

            <li>
              <NavLink
                to="/resources"
                className={({ isActive }) =>
                  isActive
                    ? "text-primary mr-2 inline-flex rounded-md px-3 py-2 text-sm font-medium"
                    : "text-foreground hover:bg-card hover:text-foreground mr-2 inline-flex rounded-md px-3 py-2 text-sm font-medium"
                }
              >
                Resources
              </NavLink>
            </li>

            <li>
              <NavLink
                to="/contact-us"
                className={({ isActive }) =>
                  isActive
                    ? "text-primary mr-2 inline-flex rounded-md px-3 py-2 text-sm font-medium"
                    : "text-foreground hover:bg-card hover:text-foreground mr-2 inline-flex rounded-md px-3 py-2 text-sm font-medium"
                }
              >
                Contact Us
              </NavLink>
            </li>
          </ul>
        </nav>
        <div className="hidden md:flex items-center text-primary-foreground gap-2">
          {/* <FontAwesomeIcon
            color="gray"
            icon={faSearch}
            onClick={() => setSearchOpen(!searchOpen)}
          />
          {searchOpen && (
            <input
              type="text"
              className="bg-background text-primary-foreground rounded p-1 ml-2"
              placeholder="Search..."
            />
          )} */}
          <Link
            to="https://x.com/LIBSENSE"
            target="_blank"
            aria-label="Twitter"
          >
            <FaTwitter color="gray" />
          </Link>

          <div className="relative group" ref={dropdownaccessibilityRef}>
            <FontAwesomeIcon
              color="gray"
              icon={faUniversalAccess}
              onClick={() => handleSubmenuToggle()}
            />
            {submenuOpen && (
              <div className="absolute right-full bg-background p-4 mt-1 rounded shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <ThemeToggle />
              </div>
            )}
          </div>
        </div>
        <div className="lg:hidden flex items-center">
          <button onClick={handleMenuToggle} className="focus:outline-none">
            <FontAwesomeIcon
              icon={menuOpen ? faTimes : faBars}
              size="lg"
              color="gray"
            />
          </button>
        </div>
      </div>
      {menuOpen && (
        <div className="lg:hidden m-auto bg-background p-4">
          <nav>
            <ul className="space-y-4 cursor-pointer">
              <li>
                <NavLink
                  to="/home"
                  className={({ isActive }) =>
                    isActive
                      ? "text-primary mr-2 inline-flex rounded-md px-3 py-2 text-sm font-medium"
                      : "text-foreground hover:bg-card hover:text-foreground mr-2 inline-flex rounded-md px-3 py-2 text-sm font-medium"
                  }
                >
                  Home
                </NavLink>
              </li>
              <li className="relative" ref={dropdownAboutRef}>
                <div
                  className="inline-flex items-center cursor-pointer"
                  onClick={handleDropdownAboutToggle}
                >
                  <div
                    className="text-foreground hover:bg-card hover:text-foreground inline-flex rounded-md pl-3 py-2 text-sm font-medium"
                    onClick={handleDropdownAboutToggle}
                  >
                    About Us
                  </div>
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    color="gray"
                    size="sm"
                    className={`ml-1 transition-transform ${
                      dropdownAbout ? "transform rotate-180" : ""
                    }`}
                  />
                </div>

                {dropdownAbout && (
                  <ul className="absolute left-0 mt-2 bg-background shadow-lg rounded-md p-4">
                    <li>
                      <NavLink
                        to="/about-us#story"
                        className={({ isActive }) =>
                          isActive
                            ? "text-primary mr-2 inline-flex rounded-md px-3 py-2 text-xs font-medium"
                            : "text-foreground hover:bg-card hover:text-foreground mr-2 inline-flex rounded-md px-3 py-2 text-xs font-medium"
                        }
                        onClick={() => {
                          setDropdownAbout(false);

                          // Check if the element with id="story" exists, then scroll to it
                          setTimeout(() => {
                            const element = document.getElementById("story");
                            if (element) {
                              element.scrollIntoView({ behavior: "smooth" });
                            }
                          }, 0);
                        }}
                      >
                        The LIBSENSE Story
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/about-us/project-initiatives"
                        className={({ isActive }) =>
                          isActive
                            ? "text-primary mr-2 inline-flex rounded-md px-3 py-2 text-xs font-medium"
                            : "text-foreground hover:bg-card hover:text-foreground mr-2 inline-flex rounded-md px-3 py-2 text-xs font-medium"
                        }
                        onClick={() => setDropdownAbout(false)}
                      >
                        Project & Initiatives
                      </NavLink>
                    </li>

                    <li>
                      <NavLink
                        to="/about-us/collaborations"
                        className={({ isActive }) =>
                          isActive
                            ? "text-primary mr-2 inline-flex rounded-md px-3 py-2 text-xs font-medium"
                            : "text-foreground hover:bg-card hover:text-foreground mr-2 inline-flex rounded-md px-3 py-2 text-xs font-medium"
                        }
                        onClick={() => setDropdownAbout(false)}
                      >
                        Collaborations
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/about-us/impact"
                        className={({ isActive }) =>
                          isActive
                            ? "text-primary mr-2 inline-flex rounded-md px-3 py-2 text-xs font-medium"
                            : "text-foreground hover:bg-card hover:text-foreground mr-2 inline-flex rounded-md px-3 py-2 text-xs font-medium"
                        }
                        onClick={() => setDropdownAbout(false)}
                      >
                        Impact
                      </NavLink>
                    </li>
                    {/* <li>
                      <NavLink
                        to="/about-us/policy-observatory"
                        className={({ isActive }) =>
                          isActive
                            ? "text-primary mr-2 inline-flex rounded-md px-3 py-2 text-xs font-medium"
                            : "text-foreground hover:bg-card hover:text-foreground mr-2 inline-flex rounded-md px-3 py-2 text-xs font-medium"
                        }
                        onClick={() => setDropdownAbout(false)}
                      >
                        Policy Observatory
                      </NavLink>
                    </li> */}
                  </ul>
                )}
              </li>

              <li className="relative" ref={dropdownWhatWeDoRef}>
                <div
                  onClick={handleDropdownToggle}
                  className="inline-flex items-center cursor-pointer"
                >
                  <div
                    className="text-foreground hover:bg-card hover:text-foreground inline-flex rounded-md pl-3 py-2 text-sm font-medium"
                    onClick={handleDropdownToggle}
                  >
                    What We Do
                  </div>
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    color="gray"
                    size="sm"
                    className={`ml-1 transition-transform ${
                      dropdownOpen ? "transform rotate-180" : ""
                    }`}
                  />
                </div>

                {dropdownOpen && (
                  <ul className="absolute left-0 mt-2 bg-background shadow-lg rounded-md p-4">
                    <li>
                      <NavLink
                        to="/what-we-do/open-infrastructure"
                        className={({ isActive }) =>
                          isActive
                            ? "text-primary mr-2 inline-flex rounded-md px-3 py-2 text-xs font-medium"
                            : "text-foreground hover:bg-card hover:text-foreground mr-2 inline-flex rounded-md px-3 py-2 text-xs font-medium"
                        }
                        onClick={() => setDropdownOpen(false)}
                      >
                        Open Science Infrastructure
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/what-we-do/open-science-policy"
                        className={({ isActive }) =>
                          isActive
                            ? "text-primary mr-2 inline-flex rounded-md px-3 py-2 text-xs font-medium"
                            : "text-foreground hover:bg-card hover:text-foreground mr-2 inline-flex rounded-md px-3 py-2 text-xs font-medium"
                        }
                        onClick={() => setDropdownOpen(false)}
                      >
                        Open Science Policy Advocacy & Governance
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/what-we-do/capacity-building"
                        className={({ isActive }) =>
                          isActive
                            ? "text-primary mr-2 inline-flex rounded-md px-3 py-2 text-xs font-medium"
                            : "text-foreground hover:bg-card hover:text-foreground mr-2 inline-flex rounded-md px-3 py-2 text-xs font-medium"
                        }
                        onClick={() => setDropdownOpen(false)}
                      >
                        Capacity Building
                      </NavLink>
                    </li>
                  </ul>
                )}
              </li>

              <li className="relative" ref={dropdownMediaRef}>
                <div
                  onClick={handleDropdownMediaToggle}
                  className="inline-flex items-center cursor-pointer"
                >
                  <div
                    className="text-foreground hover:bg-card hover:text-foreground inline-flex rounded-md pl-3 py-2 text-sm font-medium"
                    onClick={handleDropdownMediaToggle}
                  >
                    Media
                  </div>
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    color="gray"
                    size="sm"
                    className={`ml-1 transition-transform ${
                      dropdownMediaOpen ? "transform rotate-180" : ""
                    }`}
                  />
                </div>

                {dropdownMediaOpen && (
                  <ul className="absolute left-0 mt-2 bg-background shadow-lg rounded-md p-4">
                    <li>
                      <NavLink
                        to="/media/news"
                        className={({ isActive }) =>
                          isActive
                            ? "text-primary mr-2 inline-flex rounded-md px-3 py-2 text-xs font-medium"
                            : "text-foreground hover:bg-card hover:text-foreground mr-2 inline-flex rounded-md px-3 py-2 text-xs font-medium"
                        }
                        onClick={() => setDropdownMediaOpen(false)}
                      >
                        News
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/media/events"
                        className={({ isActive }) =>
                          isActive
                            ? "text-primary mr-2 inline-flex rounded-md px-3 py-2 text-xs font-medium"
                            : "text-foreground hover:bg-card hover:text-foreground mr-2 inline-flex rounded-md px-3 py-2 text-xs font-medium"
                        }
                        onClick={() => setDropdownMediaOpen(false)}
                      >
                        Events
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="https://video.wacren.net/channel/LIBSENSE/595033"
                        target="_blank"
                        className={({ isActive }) =>
                          isActive
                            ? "text-primary mr-2 inline-flex rounded-md px-3 py-2 text-xs font-medium"
                            : "text-foreground hover:bg-card hover:text-foreground mr-2 inline-flex rounded-md px-3 py-2 text-xs font-medium"
                        }
                        onClick={() => setDropdownMediaOpen(false)}
                      >
                        Videos
                      </NavLink>
                    </li>
                  </ul>
                )}
              </li>

              <li>
                <NavLink
                  to="/resources"
                  className={({ isActive }) =>
                    isActive
                      ? "text-primary inline-flex rounded-md px-3 py-2 text-sm font-medium"
                      : "text-foreground/50 hover:bg-card hover:text-foreground inline-flex rounded-md px-3 py-2 text-sm font-medium"
                  }
                >
                  Resources
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/contact-us"
                  className={({ isActive }) =>
                    isActive
                      ? "text-primary inline-flex rounded-md px-3 py-2 text-sm font-medium"
                      : "text-foreground/50 hover:bg-card hover:text-foreground inline-flex rounded-md px-3 py-2 text-sm font-medium"
                  }
                >
                  Contact Us
                </NavLink>
              </li>
            </ul>
          </nav>
          <div className="flex items-center text-primary-foreground gap-2 mt-4">
            {/* <FontAwesomeIcon
              color="gray"
              icon={faSearch}
              onClick={() => setSearchOpen(!searchOpen)}
            />
            {searchOpen && (
              <input
                type="text"
                className="bg-background text-primary-foreground rounded p-1 ml-2"
                placeholder="Search..."
              />
            )} */}

            <Link
              to="https://x.com/LIBSENSE"
              target="_blank"
              aria-label="Twitter"
            >
              <FaTwitter color="gray" />
            </Link>

            <div className="relative group" ref={dropdownaccessibilityRef}>
              <FontAwesomeIcon
                color="gray"
                icon={faUniversalAccess}
                onClick={() => handleSubmenuToggle()}
              />
              {submenuOpen && (
                <div className="absolute left-full bg-background p-4 mt-1 rounded shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                  <ThemeToggle />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </header>
  );
};

export default Navbar;
