import React, { useEffect, useState } from "react";
import HeaderImage from "../../components/HeaderImage";
import { Link } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import Stats from "../../components/Stats";
import CollaboratingCarousel from "../../components/CollaboratingCarousel";

const CopphaPage: React.FC = () => {
  const [expandedFAQ, setExpandedFAQ] = useState<number | null>(null);
  const toggleFAQ = (index: number) => {
    setExpandedFAQ(expandedFAQ === index ? null : index);
  };
  useEffect(() => {
    const element = document.getElementById("coppha");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const { ref: impactRef, inView: impactInView } = useInView({
    triggerOnce: false,
  });

  const feedbacks = [
    {
      name: "JO",
      title: "Public Health Practitioner",
      country: "Kenya",
      feedback:
        "I joined this program knowing completely nothing about peer reviewing and am coming out with clear information about it. Thank you for making it happen. To the lecturers and hosts, thank you from the bottom of my heart. I can clearly say that I am capable of performing a peer review because of you.",
    },
    {
      name: "WV",
      title: "Lecturer",
      country: "Nigeria",
      feedback:
        "The model review assignment and the answer have been something to keep learning from. I commend the organizers and participants for their valuable inputs and for making it possible for diverse researchers and scholars to be on this space. More success in future endeavors.",
    },
    {
      name: "JN",
      title: "Lecturer and Dean",
      country: "Uganda",
      feedback:
        "The reflection forums helped to internalize the concepts and learn from others. Without a doubt, following the structured, six-step format is going to make my peer review experience a better one. This is a course I highly recommend.",
    },
    {
      name: "PW",
      title: "Lecturer",
      country: "Kenya",
      feedback:
        "The trainers did an excellent job, and the online learning platform was easy to navigate. I have gained necessary skills to guide me in the next peer review assignment. Thank you.",
    },
    {
      name: "RQ",
      title: "Biologist",
      country: "Ghana",
      feedback:
        "God bless the COPPHA team for putting up this course. I am very grateful for this opportunity and cannot wait to utilize my new skill.",
    },
  ];

  const faq = [
    {
      question: "What is Diamond Open Access publishing?",
      answer: (
        <p>
          Diamond Open Access is a scholarly publishing model that eliminates
          fees for both authors and readers. This approach ensures equitable
          access to research outputs while promoting collaborative
          knowledge-sharing and fostering inclusivity in scholarly
          communication.
        </p>
      ),
    },
    {
      question: "Why is peer review important?",
      answer: (
        <p>
          Peer review is vital for maintaining the quality, accuracy, and
          credibility of scholarly publications. It serves as a foundation for
          validating research, ensuring the information published in journals is
          reliable and accurate. Through rigorous evaluation by experts, peer
          review helps preserve the integrity of scholarly work and uphold the
          standards of research.
        </p>
      ),
    },
    {
      question: "Who can participate in COPPHA courses?",
      answer: (
        <p>
          COPPHA courses are designed for African researchers, editors,
          librarians, lecturers, public health professionals, and graduate
          students seeking to build expertise in open access publishing and peer
          review.
        </p>
      ),
    },
    {
      question: "What is the cost of the course?",
      answer: (
        <p>
          The course is free of charge for all participants, with costs fully
          covered by COPPHA.
        </p>
      ),
    },
    {
      question: "What is the duration of the course?",
      answer: (
        <p>
          The course ran for five weeks, offering a structured and immersive
          learning experience through self-paced modules and live interactive
          sessions.
        </p>
      ),
    },
    {
      question: "Who can participate in COPPHA courses?",
      answer: (
        <p>
          COPPHA courses are designed for African researchers, editors,
          librarians, lecturers, public health professionals, and graduate
          students seeking to build expertise in open access publishing and peer
          review.
        </p>
      ),
    },
    {
      question: "Who are the facilitators?",
      answer: (
        <p>
          The course was facilitated by experienced public researchers with
          expertise in peer review and scholarly publishing. They guided
          participants weekly on the course platform and live Zoom sessions.
        </p>
      ),
    },
    {
      question: "Will participants receive a certificate?",
      answer: (
        <p>
          Yes, participants who successfully completed the course received a
          certificate of completion.
        </p>
      ),
    },
  ];

  return (
    <>
      <HeaderImage image="/assets/images/hero.jpg" />

      <div className="max-w-4xl mx-auto mb-12 p-4" id="coppha">
        {/* <h2 className="text-center text-xxl font-semibold text-foreground uppercase mb-4">
          Coalition for Open Access Publishing of Public Health in Africa
          (COPPHA)
        </h2> */}

        <section className="mb-4">
          <h3 className="text-center text-xl font-semibold text-foreground mb-4">
            COPPHA and Coalition Objectives
          </h3>

          <p className="text-sm text-foreground my-4">
            The{" "}
            <Link
              to="/news/671e0e605f8599b2099f4cc8"
              target="_blank"
              className="text-primary"
            >
              Coalition for Open Access Publishing of Public Health in Africa
              (COPPHA)
            </Link>{" "}
            is an innovative initiative focused on advancing Diamond Open Access
            publishing across Africa. Led by WACREN in collaboration with{" "}
            <Link
              to="https://www.publichealth.africa/"
              target="_blank"
              className="text-primary"
            >
              PublicHealth.Africa (PHA)
            </Link>
            ,{" "}
            <Link
              to="https://peoples-praxis.org/"
              target="_blank"
              className="text-primary"
            >
              Peoples-Praxis
            </Link>
            ,{" "}
            <Link
              to="https://www.afrehealth.org/"
              target="_blank"
              className="text-primary"
            >
              AFREhealth
            </Link>
            , the{" "}
            <Link
              to="https://publichealth-edu.org/"
              target="_blank"
              className="text-primary"
            >
              West African Institute of Public Health (WAIPH)
            </Link>
            , and the{" "}
            <Link
              to="https://journals.ub.umu.se/index.php/shaj"
              target="_blank"
              className="text-primary"
            >
              Somali Health Action Journal
            </Link>
            , COPPHA is committed to promoting equity, accessibility, and
            excellence in publishing. Diamond Open Access publishing, which
            ensures that authors and readers face no financial barriers, is
            central to COPPHA’s mission of equitable research dissemination and
            fostering high-quality, open access publishing practices across the
            continent.
          </p>

          <h4 className="text-left text-lg font-medium text-foreground mb-2">
            COPPHA’s core objectives include:
          </h4>

          <ul className="text-sm text-foreground mb-4 list-disc">
            <li className="mb-2">
              Supporting the growth and sustainability of Diamond Open Access
              publishing in Africa.
            </li>
            <li className="mb-2">
              Reducing inequalities in research access, publication, and
              dissemination.
            </li>
            <li className="mb-2">
              Equipping African researchers with the skills and knowledge to
              participate effectively in peer review processes and building the
              capacity needed to support Diamond OA journals in Africa to
              enhance trust in their outputs.
            </li>
            <li className="mb-2">
              Building a Community of Practice on Open Access Publishing in
              Africa.
            </li>
          </ul>
        </section>

        <section className="mb-4 mt-8">
          <h3 className="text-center text-xl font-semibold text-foreground mb-4">
            COPPHA Course Description
          </h3>

          <p className="text-sm text-foreground my-4">
            The 2024 COPPHA Peer Reviewing Course was delivered by WACREN and{" "}
            <Link
              to="https://peoples-praxis.org/"
              target="_blank"
              className="text-primary"
            >
              Peoples-Praxis
            </Link>
            , key partners in the Coalition for Open Access Publishing of Public
            Health in Africa (COPPHA). The course’s primary objective was to
            train African researchers in all aspects of the peer review process,
            enabling them to conduct high-quality reviews. Key skills developed
            through the course included critically assessing research
            manuscripts, providing constructive and actionable feedback, and
            upholding ethical standards.
          </p>
          <p className="text-sm text-foreground my-4">
            Delivered as a five-week Massive Open Online Course (MOOC) from
            October 7 to November 10, 2024, the program blended asynchronous
            learning on the Peoples-Praxis platform with optional live Zoom
            sessions. The course covered:
          </p>

          <ul className="text-sm text-foreground mb-4 list-disc">
            <li className="mb-2">
              <Link
                to="https://video.wacren.net/media/COPPHA+Week+1+-+The+Peer+Review+Process+and+Identifying+Predatory+Publishers/0_wrruc7s5/713268"
                target="_blank"
                className="text-primary"
              >
                Fundamentals and Challenges of Peer Review
              </Link>
            </li>
            <li className="mb-2">
              <Link
                to="https://video.wacren.net/media/COPPHA+Week+2+-+The+Role+of+AI+in+Peer+ReviewA+Opportunities%2C+Challenges%2C+and+Ethics/0_z2qpkiy9/713268"
                target="_blank"
                className="text-primary"
              >
                AI and the Review Process
              </Link>
            </li>
            <li className="mb-2">
              <Link
                to="https://video.wacren.net/media/COPPHA+Week+3+-+How+to+Conduct+an+Effective+Peer+ReviewA+Key+Steps+and+Best+Practices/0_b2w94gg5/713268"
                target="_blank"
                className="text-primary"
              >
                How to Review a Journal Article
              </Link>
            </li>
            <li className="mb-2">
              <Link
                to="https://video.wacren.net/media/COPPHA+Week+4+Zoom+SessionA+Performing+a+Peer+Review+Assignment/0_xzf1qewe/713268"
                target="_blank"
                className="text-primary"
              >
                Performing a Peer Review Assignment
              </Link>
            </li>
          </ul>
          <p className="text-sm text-foreground my-4">
            The course concluded with participants completing a peer review
            assignment, and those who successfully completed the course received
            a certificate of completion.
          </p>
        </section>

        <section className="mb-4 mt-8">
          <h3 className="text-center text-xl font-semibold text-foreground mb-4">
            What next after the 2024 COPPHA Peer Reviewing Course?
          </h3>

          <p className="text-sm text-foreground my-4">
            Participants who successfully completed the{" "}
            <Link
              to="/news/671e0e605f8599b2099f4cc8"
              target="_blank"
              className="text-primary"
            >
              COPPHA
            </Link>{" "}
            Peer Reviewing Course are eligible to join the upcoming COPPHA Open
            Publishing Course, scheduled for the first quarter (Q1) of 2025.
            This course will focus on open publishing principles, with a focus
            on preprints and open reviews. Participants will engage with a case
            study, post a preprint on the BAOBAB repository, and complete an
            open review on the PublishNow platform. The course aims to introduce
            participants to equitable publishing models that facilitate faster
            research dissemination and build a community of practice for peer
            reviewing and innovative publishing in Africa. It is divided into
            five weekly sections:
          </p>
          <ul className="text-sm text-foreground mb-4 list-disc">
            <li className="mb-2">Open Access Publishing (Week 1)</li>
            <li className="mb-2">Open Reviewing and a Case Study (Week 2)</li>
            <li className="mb-2">Posting a Preprint (Week 3)</li>
            <li className="mb-2">Posting an Open Review (Weeks 4 & 5)</li>
          </ul>

          <motion.div
            ref={impactRef}
            initial={{ opacity: 0, y: 50 }}
            animate={impactInView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.6, ease: "easeOut" }}
            className="mt-10"
          >
            <div className="border-t border-b border-gray-600 py-6 flex justify-between">
              <Stats
                value={40}
                label="Countries Represented"
                animate={impactInView}
              />
              <Stats
                value={651}
                label="Course Participants"
                animate={impactInView}
              />
              <Stats
                value={70}
                label="Early-career Professionals"
                suffix="%"
                animate={impactInView}
              />
              <Stats
                value={203}
                label="Certificates Issued"
                // suffix="+"
                animate={impactInView}
              />
            </div>
          </motion.div>
        </section>

        <section className="mb-4 mt-8">
          <h3 className="text-center text-lg font-semibold text-foreground mb-4">
            Important Links
          </h3>
          <div className="flex justify-center space-x-4 mt-8">
            <Link
              to="https://baobab.wacren.net/communities/coppha/records"
              target="_blank"
              className="block text-center border border-foreground mb-4 px-4 py-2 rounded-md text-foreground transition duration-300 hover:text-primary"
            >
              Slides
            </Link>
            <Link
              to="https://video.wacren.net/channel/COPPHA/713268"
              target="_blank"
              className="block text-center border border-foreground mb-4 px-4 py-2 rounded-md text-foreground transition duration-300 hover:text-primary"
            >
              Videos
            </Link>
            <Link
              to="/news/671e0e605f8599b2099f4cc8"
              target="_blank"
              className="block text-center border border-foreground mb-4 px-4 py-2 rounded-md text-foreground transition duration-300 hover:text-primary"
            >
              Blog
            </Link>
          </div>
        </section>

        <section className="py-10 px-5">
          <div className="max-w-4xl mx-auto text-center">
            <h4 className="text-xl font-semibold text-foreground mb-8">
              Some of the Participants’ Feedback:
            </h4>
            <div className="space-y-6">
              {feedbacks.map(({ name, title, country, feedback }, index) => (
                <div key={index} className="text-left">
                  <p className="font-medium text-foreground">
                    {name}, {title}, {country}:
                  </p>
                  <blockquote className="mt-2 text-foreground text-xs italic border-l-4 pl-4 border-primary">
                    “{feedback}”
                  </blockquote>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section className="py-10 px-5">
          <div className="max-w-4xl mx-auto text-center">
            <h4 className="text-xl font-semibold text-foreground mb-8">
              Collaborating organizations
            </h4>
            <CollaboratingCarousel />
          </div>
        </section>

        <section className="py-10 px-5">
          <h4 className="text-xl font-semibold text-foreground mb-8">
            Collaborating organizations
          </h4>
          <div className="space-y-4">
            {faq.map((faq, index) => (
              <div
                key={index}
                className="bg-primary text-primary-foreground p-4 rounded-md"
              >
                <div
                  className="flex justify-between items-center cursor-pointer"
                  onClick={() => toggleFAQ(index)}
                >
                  <p className="text-sm text-primary-foreground">
                    {faq.question}
                  </p>
                  <button className="text-xl font-bold">
                    {expandedFAQ === index ? "-" : "+"}
                  </button>
                </div>
                {expandedFAQ === index && (
                  <div className="mt-4 bg-card p-4 text-left text-foreground rounded-md">
                    <p className="text-sm text-foreground">{faq.answer}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </section>
      </div>
    </>
  );
};

export default CopphaPage;
