// src/components/WorkingGroupForm.tsx
import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { BASE_API_URL } from "../../utils/constants";

interface FormData {
  fullName: string;
  organization: string;
  message: string;
}

// Validation schema using Yup
const validationSchema = Yup.object({
  fullName: Yup.string().required("Full Name is required"),
  organization: Yup.string().required("organization is required"),
  message: Yup.string().required("Please describe your message"),
});

interface MessageFormProps {
  onSuccess: () => void;
}

const FundActivitiesForms: React.FC<MessageFormProps> = ({ onSuccess }) => {
  const [loading, setLoading] = useState(false);

  const initialValues: FormData = {
    fullName: "",
    organization: "",
    message: "",
  };

  const handleSubmit = async (values: FormData) => {
    setLoading(true);
    // const formDataToSend = new FormData();
    // Object.entries(values).forEach(([key, value]) => {
    //   if (key === "profile" && value) {
    //     formDataToSend.append(key, value);
    //   } else {
    //     formDataToSend.append(key, value as string);
    //   }
    // });

    // console.log("values: ", values);

    try {
      const response = await axios.post(
        `${BASE_API_URL}libsense/fund-activities`,
        values,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      setLoading(false);
      alert(response.data.message);
      onSuccess();
    } catch (error: any) {
      const err =
        error?.response?.data?.error ||
        "Form submission failed.Try again after sometime";
      setLoading(false);
      alert(err);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      {() => (
        <>
          <h2 className="text-foreground mb-2 text-lg text-center">
            Fund LIBSENSE Activities
          </h2>
          <Form className="mx-auto p-4 h-96 overflow-hidden">
            {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-6"> */}
            <div className="mb-4">
              <label
                htmlFor="fullName"
                className="block text-sm font-medium text-foreground"
              >
                Full Name
              </label>
              <Field
                type="text"
                name="fullName"
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
              />
              <ErrorMessage
                name="fullName"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>
            {/* </div> */}

            <div className="mb-4">
              <label
                htmlFor="organization"
                className="block text-sm font-medium text-foreground"
              >
                Organisation / Institution
              </label>
              <Field
                type="text"
                name="organization"
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
              />
              <ErrorMessage
                name="organization"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>

            <div className="mb-4">
              <label
                htmlFor="message"
                className="block text-sm font-medium text-foreground"
              >
                Message
              </label>
              <Field
                as="textarea"
                name="message"
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
              />
              <ErrorMessage
                name="message"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>

            <button
              type="submit"
              disabled={loading}
              className="w-full bg-primary text-primary-foreground py-2 px-4 rounded-md hover:bg-primary-foreground transition duration-300"
            >
              {loading ? "Please wait ..." : "Submit"}
            </button>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default FundActivitiesForms;
