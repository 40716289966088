import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFetchNewsById } from "../../../hooks/all.hooks";
import DetailsBackground from "../../../components/NewsDetailsBg";

const NewsDetail: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const {
    data: newsDetail,
    isLoading,
    isError,
  } = useFetchNewsById(id as string);

  const handleReadMore = (relatedId: string) => {
    navigate(`/news/${relatedId}`);
  };

  if (isLoading) return <p>Loading...</p>;
  if (isError)
    return <p className="text-red-500">{"Failed to fetch news details."}</p>;

  return (
    <>
      <DetailsBackground title={newsDetail.title} />
      <div className="max-w-4xl mx-auto p-6">
        {newsDetail.imageUrl && (
          <img
            src={newsDetail.imageUrl}
            alt={newsDetail.title}
            className="w-full h-auto mb-6"
          />
        )}
        <div
          className="text-xs news-content"
          dangerouslySetInnerHTML={{ __html: newsDetail.content }}
        />

        {/* Related Posts Section */}
        <div className="mt-12">
          <h2 className="text-2xl font-semibold mb-4">Related Posts</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
            {newsDetail.relatedPosts.map((related: any) => (
              <div
                key={related._id}
                className="flex items-center space-x-4 cursor-pointer"
                onClick={() => handleReadMore(related._id)}
              >
                <img
                  src={related.imageUrl}
                  alt={related.title}
                  className="w-24 h-24 object-contain rounded-md"
                />
                <div>
                  <h3 className="text-xs font-semibold">{related.title}</h3>
                  <p className="text-xxs">
                    {new Date(related.date).toLocaleDateString()}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default NewsDetail;
