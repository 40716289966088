import React from "react";
import { Link } from "react-router-dom";

const Institutional: React.FC = () => {
  return (
    <>
      <p className="text-sm text-foreground mb-4">
        LIBSENSE provides an{" "}
        <Link
          to="https://baobab.wacren.net/"
          target="_blank"
          className="text-primary"
        >
          {" "}
          open data repository and preprint platform
        </Link>{" "}
        ,{" "}
        <Link
          to="https://publishnow.wacren.net/"
          target="_blank"
          className="text-primary"
        >
          {" "}
          open publishing and peer review infrastructure
        </Link>
        , and{" "}
        <Link
          to="https://pidslink.wacren.net/"
          target="_blank"
          className="text-primary"
        >
          {" "}
          persistent identifiers
        </Link>{" "}
        (PIDs) based on{" "}
        <Link to="https://arks.org/" target="_blank" className="text-primary">
          {" "}
          ARKs
        </Link>{" "}
        for African academic and research communities to enhance the visibility
        and accessibility of African scholarly content and make research data
        FAIR (Findable Accessible Interoperable and Reusable). LIBSENSE proposed
        <Link
          to="https://zenodo.org/records/7970386"
          target="_blank"
          className="text-primary"
        >
          {" "}
          a roadmap for implementing PIDs in Africa
        </Link>
        . These interoperable, scalable services are available to African
        research and education institutions.
      </p>
    </>
  );
};

export default Institutional;
