import React from "react";

interface DetailsBackgroundProps {
  title: string;
}

const DetailsBackground: React.FC<DetailsBackgroundProps> = ({ title }) => {
  return (
    <div className="relative h-96 w-full flex items-center justify-center bg-gradient-to-r from-blue-600 to-orange-600">
      <h2 className="text-xl w-full sm:w-1/2 text-primary-foreground font-bold text-center">
        {title}
      </h2>
    </div>
  );
};

export default DetailsBackground;
