import React, { useEffect, useState } from "react";
import HeaderImage from "../../components/HeaderImage";

const PolicyObservatory: React.FC = () => {
  // States for dropdown selections
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedPolicyType, setSelectedPolicyType] = useState("");
  const [selectedPolicyElement, setSelectedPolicyElement] = useState("");
  const [selectedPolicyRequirement, setSelectedPolicyRequirement] =
    useState("");

  // Data for the dropdowns
  const countries = [
    "Algeria",
    "Angola",
    "Benin",
    "Ghana",
    "Nigeria",
    "Togo",
    "Gambia",
  ];
  const policyTypes = ["Institutional OS/OA", "National OS/OA"];
  const policyElements = [
    "open access repositories for publications",
    "open access repositories for data",
    "open access journals and publishing platforms",
    "supporting no-fee OA journals",
    "supporting OA journals that charge APCs",
    "other open science infrastructures",
    "recognising OA/open science in research assessment",
    "open data for government and private sector",
  ];
  const policyRequirements = [
    "depositing in a repository upon publication",
    "immediate open access under an open content license",
    "delayed open access under an open content license",
    "making available scientific outputs",
    "rights retention",
    "depositing preprints",
    "open access to other types of publications",
  ];

  // Handle form submission
  const handleSearch = () => {
    if (
      !selectedCountry ||
      !selectedPolicyType ||
      !selectedPolicyElement ||
      !selectedPolicyRequirement
    ) {
      alert("Please select all fields before searching.");
      return;
    }

    // Make API request here with selected values
    console.log({
      country: selectedCountry,
      policyType: selectedPolicyType,
      policyElement: selectedPolicyElement,
      policyRequirement: selectedPolicyRequirement,
    });

    // Example: Fetch data from backend (replace with real API endpoint)
    // fetch('/api/search', {
    //   method: 'POST',
    //   body: JSON.stringify({
    //     country: selectedCountry,
    //     policyType: selectedPolicyType,
    //     policyElement: selectedPolicyElement,
    //     policyRequirement: selectedPolicyRequirement
    //   }),
    //   headers: {
    //     'Content-Type': 'application/json'
    //   }
    // })
    //   .then(response => response.json())
    //   .then(data => console.log(data))
    //   .catch(error => console.error('Error:', error));
  };

  useEffect(() => {
    const element = document.getElementById("policy-observatory");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <>
      <HeaderImage image="/assets/images/backgrounds/observatory.jpg" />

      <div className="container mx-auto my-14" id="policy-observatory">
        <h2 className="text-center text-xxl font-semibold text-foreground uppercase mb-4">
          Open Science Observatory
        </h2>
        <p className="text-sm text-foreground mb-6">
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Hic saepe,
          rem numquam iste eum eveniet dolor quos consequuntur quo odit? Vitae,
          ratione dolorum. Aperiam, praesentium reprehenderit. Exercitationem,
          nam. Incidunt, eligendi. Illum quam totam fuga modi consequatur ut quo
          delectus? Illum ducimus repellat aut eligendi recusandae. Molestias,
          inventore dignissimos placeat earum id eaque mollitia corporis
          suscipit doloribus. Minus illo hic consequuntur. Nostrum tenetur odit
          beatae commodi obcaecati repellendus illo, ea quod, saepe officiis
          eveniet cumque quasi at officia asperiores recusandae tempora.
          Voluptates asperiores ex odio porro, harum provident ea quod corrupti.
          Iusto minima alias enim aspernatur velit. Amet dolorem suscipit
          nostrum tenetur animi rem, quisquam, quasi deleniti, ea possimus
          perspiciatis neque aliquid culpa dolore non accusamus nam. Aliquid
          commodi maiores quos!
        </p>

        {/* Form Dropdowns */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
          {/* Country Dropdown */}
          <div className="relative">
            <select
              value={selectedCountry}
              onChange={(e) => setSelectedCountry(e.target.value)}
              className="block w-full px-4 py-2 border border-gray-300 rounded-md"
            >
              <option value=""> Select Country</option>
              {countries.map((country) => (
                <option key={country} value={country}>
                  {country}
                </option>
              ))}
            </select>
          </div>

          {/* Policy Type Dropdown */}
          <div className="relative">
            <select
              value={selectedPolicyType}
              onChange={(e) => setSelectedPolicyType(e.target.value)}
              className="block w-full px-4 py-2 border border-gray-300 rounded-md"
            >
              <option value="">Select Policy Type</option>
              {policyTypes.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>

          {/* Policy Elements Dropdown */}
          <div className="relative">
            <select
              value={selectedPolicyElement}
              onChange={(e) => setSelectedPolicyElement(e.target.value)}
              className="block w-full px-4 py-2 border border-gray-300 rounded-md"
            >
              <option value="">Select Policy Elements</option>
              {policyElements.map((element) => (
                <option key={element} value={element}>
                  {element}
                </option>
              ))}
            </select>
          </div>

          {/* Policy Requirements Dropdown */}
          <div className="relative">
            <select
              value={selectedPolicyRequirement}
              onChange={(e) => setSelectedPolicyRequirement(e.target.value)}
              className="block w-full px-4 py-2 border border-gray-300 rounded-md"
            >
              <option value="">Select Policy Requirement</option>
              {policyRequirements.map((requirement) => (
                <option key={requirement} value={requirement}>
                  {requirement}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Search Button */}
        <div className="text-center">
          <button
            onClick={handleSearch}
            disabled={
              !selectedCountry ||
              !selectedPolicyType ||
              !selectedPolicyElement ||
              !selectedPolicyRequirement
            }
            className="px-4 py-2 bg-primary text-primary-foreground rounded-md hover:bg-gray-800 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Search
          </button>
        </div>
      </div>
    </>
  );
};

export default PolicyObservatory;
