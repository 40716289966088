import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";

interface NavItems {
  sections: string[];
  content: { [key: string]: React.ReactNode };
  title: string;
  paragraphs?: any[];
  initialSection?: string;
  route: string;
}

const WhatWeDo: React.FC<NavItems> = ({
  sections,
  content,
  title,
  paragraphs,
  initialSection,
  route,
}) => {
  const navigate = useNavigate();

  // Set initial section from the prop, fallback to sections[0] if undefined
  const [selectedSection, setSelectedSection] = useState(
    initialSection || sections[0].toLowerCase()
  );

  // Update selected section when initialSection changes (e.g., from URL changes)
  useEffect(() => {
    if (initialSection) {
      setSelectedSection(initialSection);
    }
  }, [initialSection]);

  const handleSectionClick = (section: string) => {
    setSelectedSection(section.toLowerCase());
    navigate(`/what-we-do/${route}/${section.toLowerCase()}`); // Update URL
  };

  useEffect(() => {
    const element = document.getElementById("main-details");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <div className="max-w-6xl mx-auto my-8 p-4" id="main-details">
      {/* Title */}
      <h3 className="text-center text-lg font-semibold text-foreground mb-4">
        {title}
      </h3>

      {/* Description */}
      <div className="space-y-4 mb-8">
        {paragraphs &&
          paragraphs.map((paragraph, index) => (
            <p key={index} className="text-sm text-foreground mb-4">
              {paragraph}
            </p>
          ))}
      </div>

      {/* Divider */}
      <hr className="border-t border-gray-300 mb-8" />

      {/* Content Sections */}
      <div className="flex">
        {/* Left Side Navigation */}
        <div className="w-1/4 space-y-4 font-semibold">
          {sections.map((section) => (
            <NavLink
              key={section}
              to="#"
              onClick={() => handleSectionClick(section)}
              className={`block ${selectedSection === section.toLowerCase() ? "text-primary" : "text-foreground"}`}
            >
              {section}
            </NavLink>
          ))}
        </div>

        {/* Right Side Content */}
        <div className="w-3/4 space-y-4 text-gray-700">
          {content[selectedSection]} {/* Render the selected content */}
        </div>
      </div>
    </div>
  );
};

export default WhatWeDo;
