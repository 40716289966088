import React, { useEffect } from "react";

interface SharedRepositoriesProps {
  imageUrl?: string;
  title: string;
  paragraphs: string[];
  buttons?: {
    label: string;
    onClick: () => void;
    variant: "default" | "cta";
  }[];
}

const SharedRepositories: React.FC<SharedRepositoriesProps> = ({
  title,
  paragraphs,
  buttons,
}) => {
  useEffect(() => {
    const element = document.getElementById("shared-repo");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <div className="max-w-4xl mx-auto mb-12 p-6" id="shared-repo">
      {/* Section Title */}
      <h2 className="text-center text-xxl font-semibold text-foreground uppercase mb-4">
        {title}
      </h2>

      {/* Paragraphs */}
      <div className="space-y-6 mb-8">
        {paragraphs.map((paragraph, index) => (
          <p key={index} className="text-sm text-foreground">
            {paragraph}
          </p>
        ))}
      </div>

      {/* Buttons */}
      <div className="flex justify-center gap-4">
        {buttons &&
          buttons.map((button, index) => (
            <button
              key={index}
              onClick={button.onClick}
              className={`px-6 py-2 rounded-md text-white ${
                button.variant === "cta"
                  ? "bg-red-500 hover:bg-red-600"
                  : "hover:bg-primary-600 bg-primary text-xs font-semibold text-primary-foreground"
              } transition-colors duration-300`}
            >
              {button.label}
            </button>
          ))}
      </div>
    </div>
  );
};

export default SharedRepositories;
