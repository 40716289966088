import React, { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";

const ContactMenu: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const mainContent = document.getElementById("main-content");

    if (!mainContent) return;

    const toggleVisibility = () => {
      if (mainContent.scrollTop > 20) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    mainContent.addEventListener("scroll", toggleVisibility);

    return () => mainContent.removeEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <div>
      {isVisible && (
        <div className="fixed bottom-6 left-6">
          {/* Main button */}
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="w-12 h-12 rounded-full bg-white shadow-lg flex items-center justify-center focus:outline-none hover:scale-110 transition-transform duration-200"
          >
            {isOpen ? (
              <FaTimes color="gray" />
            ) : (
              <span role="img" aria-label="contact-icon" className="text-2xl">
                📞
              </span>
            )}
          </button>

          {/* Menu options */}
          {isOpen && (
            <div className="mt-4 space-y-3 flex flex-col items-end">
              {/* Email */}
              <a
                href="mailto:libsense@ren.africa"
                target="_blank"
                className="p-3 bg-primary rounded-full shadow-md flex items-center space-x-2 hover:scale-105 transition-transform duration-200"
                rel="noreferrer"
              >
                <span role="img" aria-label="email-icon" className="text-lg">
                  ✉️
                </span>
                <span className="text-sm text-primary-foreground">
                  SEND AN EMAIL
                </span>
              </a>

              {/* Phone */}
              <a
                href="tel:+233302942873"
                target="_blank"
                className="p-3 bg-primary rounded-full shadow-md flex items-center space-x-2 hover:scale-105 transition-transform duration-200"
                rel="noreferrer"
              >
                <span role="img" aria-label="phone-icon" className="text-lg">
                  📞
                </span>
                <span className="text-sm text-primary-foreground">
                  +233 3029 42873
                </span>
              </a>

              {/* WhatsApp */}
              {/* <a
                href="https://wa.me/123456789"
                target="_blank"
                className="p-3 bg-primary rounded-full shadow-md flex items-center space-x-2 hover:scale-105 transition-transform duration-200"
                rel="noreferrer"
              >
                <span role="img" aria-label="whatsapp-icon" className="text-lg">
                  🟢
                </span>
                <span className="text-sm text-primary-foreground">
                  WHATSAPP CHAT
                </span>
              </a> */}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ContactMenu;
