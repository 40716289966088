import React from "react";
import { useNavigate } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const HeaderBanner: React.FC = () => {
  const navigate = useNavigate();

  const handleReadMoreClick = (link: any) => {
    if (link.internal) {
      navigate(link.link);
    } else {
      window.open(link.link, "_blank");
    }
  };

  const slides = [
    // {
    //   title: "Empowering Africa Through Open Science",
    //   description:
    //     "LIBSENSE (Library Support for Embedded NREN Services and E-infrastructure) is a WACREN-initiated, community-driven, pan-African programme aimed at building a community of practice for open science...",
    //   buttonText: "Read More",
    //   link: "/about-us",
    //   imageUrl: "/assets/images/hero.jpeg",
    //   internal: true,
    //   showlabel: true,
    // },
    {
      title: "LIBSENSE Impact Report",
      description:
        "LIBSENSE makes a significant contribution to open science advancement in Africa.",
      buttonText: "Read More",
      link: "/about-us/impact",
      imageUrl: "/assets/images/hero.jpeg",
      internal: true,
      showlabel: true,
    },
    {
      title: "Collaborate for Innovation",
      description:
        "COPPHA is an innovative initiative focused on promoting equity, accessibility, and excellence in Diamond Open Access publishing in public health across Africa",
      buttonText: "Read More",
      link: "/coppha",
      imageUrl: "/assets/images/hero.jpg",
      internal: true,
      showlabel: true,
    },
    {
      title: "Strengthening Open Science in Africa",
      description:
        "LIBSENSE is building capacity, fostering collaboration, catalysing policies and easing access to infrastructure",
      buttonText: "Read More",
      link: "/about-us",
      internal: true,
      imageUrl: "/assets/images/backgrounds/about1.jpg",
      showlabel: true,
    },
  ];

  return (
    <Carousel
      showArrows={true}
      infiniteLoop={true}
      autoPlay={true}
      interval={5000}
      showThumbs={false}
      showStatus={false}
    >
      {slides.map((slide, index) => (
        <div
          key={index}
          className="relative h-[80vh] sm:h-[60vh] md:h-screen bg-cover bg-center"
          style={{ backgroundImage: `url(${slide.imageUrl})` }}
        >
          {slide.showlabel && (
            <div className="relative z-10 flex flex-col justify-center items-start h-full p-4 md:p-8">
              <div className="bg-gradient-to-r from-blue-900 to-transparent p-4 md:p-8 rounded-sm">
                <h1 className="text-white text-xl sm:text-3xl md:text-5xl font-bold mb-2 md:mb-4">
                  {slide.title}
                </h1>
                <p className="text-white text-sm sm:text-base md:text-lg max-w-md mb-4 md:mb-6">
                  {slide.description}
                </p>
                <button
                  className="w-full sm:w-1/2 hover:bg-primary-600 rounded-md bg-primary px-4 py-2 text-xs sm:text-sm font-semibold text-primary-foreground"
                  onClick={() => handleReadMoreClick(slide)}
                >
                  {slide.buttonText}
                </button>
              </div>
            </div>
          )}
        </div>
      ))}
    </Carousel>
  );
};

export default HeaderBanner;
