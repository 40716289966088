import React from "react";
import WhatWeDo from "../../components/ReusableData";
import HeaderImage from "../../components/HeaderImage";
import { useParams } from "react-router-dom";
import Institutional from "./CB/Institution";
import NationalPage from "./CB/National";
import RegionalPage from "./CB/Regional";

const CapacityBuildingPage: React.FC = () => {
  const { policyType } = useParams<{ policyType: string }>();
  const sections = ["Institutional", "National", "Regional"];

  const content = {
    institutional: <Institutional />,
    national: <NationalPage />,
    regional: <RegionalPage />,
  };

  return (
    <>
      <HeaderImage image="/assets/images/backgrounds/capacity.jpg" />
      <WhatWeDo
        sections={sections}
        content={content}
        title="Capacity Building"
        paragraphs={[
          <p>
            LIBSENSE actively promotes the adoption of open science through
            targeted capacity-building initiatives. These efforts focus on
            empowering institutional leaders, researchers, and librarians with
            the skills and knowledge necessary to develop, implement, and
            sustain open science policies.
          </p>,
        ]}
        initialSection={policyType?.toLowerCase() || "institutional"}
        route="capacity-building"
      />
    </>
  );
};

export default CapacityBuildingPage;
