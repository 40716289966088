import React from "react";
import { Link } from "react-router-dom";

const RegionalPage: React.FC = () => {
  return (
    <>
      <p className="text-sm text-foreground mb-4">
        LIBSENSE and RUFORUM are{" "}
        <Link
          to="/about-us/collaborations/ruforum"
          target="_blank"
          className="text-primary"
        >
          collaborating
        </Link>{" "}
        to leverage digital technologies provided by WACREN to enhance the
        visibility, accessibility and impact of agricultural research outputs.
        In this collaboration, over 18,000 researchers and librarians in more
        than 160 African agriculture universities will access the open data
        repository service - BAOBAB.
      </p>
    </>
  );
};

export default RegionalPage;
