import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import JoinEcrForm from "../routes/RDM/JoinEcrForm";
import JoinNetworkForm from "../routes/CallToAction/JoinNetworkForms";
import VolunteerForms from "../routes/CallToAction/VolunteerForms";
import FundActivitiesForms from "../routes/CallToAction/FundActivities";

const ButtonGroup: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedButton, setSelectedButton] = useState<any>(null);

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const buttons = [
    {
      id: 1,
      label: "Subscribe to our mailing list",
      type: "external",
      link: "https://groups.google.com/a/ren.africa/g/libsense-discuss",
      showPopUp: false,
    },
    {
      id: 2,
      label: "Join LIBSENSE ECR Team",
      type: "internal",
      link: "/rdm-advocacy",
      showPopUp: true,
    },
    {
      id: 3,
      label: "Be a LIBSENSE country volunteer",
      type: "internal",
      link: "/volunteer",
      showPopUp: true,
    },
    {
      id: 4,
      label: "Join our Network of Experts",
      type: "internal",
      link: "/network-of-experts",
      showPopUp: true,
    },
    {
      id: 5,
      label: "Fund LIBSENSE activities",
      type: "internal",
      link: "/fund-activities",
      showPopUp: true,
    },
    {
      id: 6,
      label: "For general correspondence",
      type: "mail",
      link: "libsense@ren.africa",
      showPopUp: false,
    },
  ];

  const handleButtonClick = (button: any) => {
    if (button.showPopUp) {
      setSelectedButton(button);
      setIsModalOpen(true);
    }
  };

  return (
    <>
      <div className="container mx-auto my-14">
        <h2 className="text-center text-xxl font-semibold text-foreground uppercase mb-4">
          Get Involved
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {buttons.map((button, index) =>
            button.showPopUp ? (
              <button
                key={button.id}
                onClick={() => handleButtonClick(button)}
                className="block text-center border border-foreground px-6 py-3 rounded-md text-foreground transition duration-300 hover:text-primary"
              >
                {button.label}
              </button>
            ) : button.type === "internal" ? (
              <NavLink
                key={button.id}
                to={button.link}
                className={({ isActive }) =>
                  `block text-center border border-foreground px-6 py-3 rounded-md text-foreground transition duration-300 ${
                    isActive ? "text-foreground" : "hover:text-primary"
                  }`
                }
              >
                {button.label}
              </NavLink>
            ) : button.type === "external" ? (
              <a
                key={button.id}
                href={`${button.link}`}
                target="_blank"
                rel="noreferrer"
                className="block text-center border border-foreground px-6 py-3 rounded-md text-foreground transition duration-300 hover:text-primary"
              >
                {button.label}
              </a>
            ) : (
              <a
                key={button.id}
                href={`mailto:${button.link}`}
                target="_blank"
                rel="noreferrer"
                className="block text-center border border-foreground px-6 py-3 rounded-md text-foreground transition duration-300 hover:text-primary"
              >
                {button.label}
              </a>
            )
          )}
        </div>
      </div>

      {/* Modal for buttons with showPopUp: true */}
      {selectedButton && isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="bg-card rounded-lg p-6 max-w-md w-full relative">
            {/* Close button */}
            <button
              onClick={toggleModal}
              type="button"
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
            >
              <FontAwesomeIcon icon={faTimes} className="w-6 h-6" />
            </button>
            {/* Conditional rendering of forms */}
            {selectedButton.label === "Join LIBSENSE ECR Team" && (
              <JoinEcrForm onSuccess={toggleModal} />
            )}
            {selectedButton.label === "Join our Network of Experts" && (
              <div>
                <JoinNetworkForm onSuccess={toggleModal} />
              </div>
            )}
            {selectedButton.label === "Be a LIBSENSE country volunteer" && (
              <div>
                <VolunteerForms onSuccess={toggleModal} />
              </div>
            )}
            {selectedButton.label === "Fund LIBSENSE activities" && (
              <div>
                <FundActivitiesForms onSuccess={toggleModal} />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ButtonGroup;
