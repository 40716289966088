import React from "react";
import { Link } from "react-router-dom";

const NationalPage: React.FC = () => {
  return (
    <>
      <h3 className="text-center text-lg font-semibold text-foreground mb-4">
        National Open Science Roadmaps
      </h3>
      <p className="text-sm text-foreground mb-4">
        At the{" "}
        <Link
          to="/what-we-do/open-science-policy/roadmaps"
          target="_blank"
          className="text-primary"
        >
          National Open Science Roadmaps
        </Link>
        , stakeholders in open science, including policymakers, university
        leadership, researchers, and librarians, engage in conversations to
        contribute to open science policy formulation, using the UNESCO Open
        Science Recommendation as the backdrop. Countries such as Botswana, Côte
        d'Ivoire, Ethiopia, Ghana, Lesotho, Mozambique, Nigeria, Somalia,
        Tanzania, Sierra Leone and Uganda have committed to these initiatives.
        LIBSENSE, through its national engagement, provided a{" "}
        <Link
          to="https://baobab.wacren.net/records/mb2t3-5t628"
          target="_blank"
          className="text-primary"
        >
          national policy template
        </Link>{" "}
        that contributed to establishing open science policies in Sierra Leone
        and Cote d’Ivoire.
      </p>

      <p className="text-sm text-foreground mb-4">
        To empower stakeholders in the academic research and publishing
        ecosystem, LIBSENSE has held{" "}
        <Link
          to="https://video.wacren.net/channel/LIBSENSE/595033"
          target="_blank"
          className="text-primary"
        >
          webinars
        </Link>{" "}
        on skills profiles and open access publishing, research data repository
        management and interoperability and workshops on using persistent
        identifiers and eduID. The development programs for the ECR Teams
        include training on research ethics and best practices.
      </p>
    </>
  );
};

export default NationalPage;
