import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  Navigate,
} from "react-router-dom";
import ReactGA from "react-ga4";
import { GOOGLETRACKERKEY } from "./utils/constants";
import PageTitle from "./components/PageTitle";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer/Footer";
import Loader from "./components/loader";
import ScrollToTopButton from "./components/ScrollToTopBtn";

// Routes and Pages
import Home from "./routes/Home";
import AboutUs from "./routes/About/AboutUs";
import ProjectsInitiative from "./routes/About/Projects_Initiative";
import Collaborations from "./routes/About/Collaborations";
import ImpactSection from "./routes/About/Impact";
import PolicyObservatory from "./routes/About/PolicyObservatory";
import LIBSENSEEIFL from "./routes/About/CollaborationsEefl";
import RuforumPage from "./components/workshop/Ruforum";
import OpenPolicyPage from "./routes/WhatWeDo/OpenPolicy";
import CapacityBuildingPage from "./routes/WhatWeDo/CapacityBuilding";
import OpenAccessPage from "./routes/WhatWeDo/OpenAccess";
import RoadmapPage from "./routes/WhatWeDo/OPS/NationalRoadmap";
import SharedRepo from "./routes/Repo/SharedRepo";
import SharedPublishing from "./routes/Repo/SharedPublishing";
import OpenData from "./routes/Repo/OpenData";
import PIDsService from "./routes/Repo/PIDs";
import NewsSection from "./routes/Media/News/News";
import NewsDetail from "./routes/Media/News/NewsDetail";
import EventSection from "./routes/Media/Events/Event";
import EventDetail from "./routes/Media/Events/EventDetail";
import Resources from "./routes/Resources";
import ContactUs from "./routes/ContactUs";
import NetworkOfExperts from "./routes/CallToAction/NetworkOfExperts";
import RDMPage from "./routes/RDM/Rdm";
import ECRGhanaPage from "./routes/RDM/ECRGhana";
import ECRNigeriaPage from "./routes/RDM/ECRNigeria";
import WorkshopDetail from "./routes/WhatWeDo/OPS/RegionalWorkshop";
import NotFoundPage from "./routes/NotFound";
import CopphaPage from "./routes/About/COPPHA";

// Initialize Google Analytics
ReactGA.initialize(GOOGLETRACKERKEY);

// Centralized Redirect Configuration
const redirects = [
  { from: "/", to: "/home" },
  {
    from: "/en/open-science/institutional/",
    to: "/what-we-do/open-science-policy/institutional",
  },
  {
    from: "/en/open-science/national/",
    to: "/what-we-do/open-science-policy/national",
  },
  {
    from: "/en/open-science/regional/",
    to: "/what-we-do/open-science-policy/regional",
  },
  {
    from: "/en/open-science/regional-workshop-3/",
    to: "/what-we-do/open-science-policy/regional",
  },
  {
    from: "/en/open-science/regional-workshop-2/",
    to: "/what-we-do/open-science-policy/regional",
  },
  {
    from: "/en/open-science/regional-workshop-1/",
    to: "/what-we-do/open-science-policy/regional",
  },
  {
    from: "/en/open-science/libsense-and-dora-webinar/",
    to: "/what-we-do/open-science-policy/regional",
  },
  {
    from: "/en/open-science/continental/",
    to: "/what-we-do/open-science-policy/regional",
  },
  { from: "/en/news/", to: "/media/news" },
  { from: "/en/events/", to: "/media/events" },
  { from: "/en/support/", to: "/contact-us" },
  { from: "/en/network-of-experts/", to: "/network-of-experts" },
  { from: "/en/libsense-early-career-researcher-ecr-initiative/", to: "/ecr" },
  { from: "/en/ghana-ecr/", to: "/ecr-ghana" },
  { from: "/en/nigeria-ecr/", to: "/ecr-nigeria" },
];

// Centralized Metadata Configuration
const pageMetadata = {
  home: {
    title: "Home | LIBSENSE",
    description:
      "Welcome to LIBSENSE, fostering open science policy and governance in Africa.",
    keywords:
      "Open Science, LIBSENSE, Africa, Policy, Governance, education, research, researcher, professional",
  },
  sharedRegional: {
    title: "Regional Shared Repository | LIBSENSE",
    description: "Regional Shared Repository",
    keywords:
      "Open Science, BAOBAB, Regional Shared Repository, Africa, Policy, Governance, education, research, researcher, professional",
  },
  publishService: {
    title: "Publishing Service | LIBSENSE",
    description: "Publishing Platform for organization or institutions",
    keywords:
      "Open Science, PublishNow, Publishing Service, Platform, Africa, Policy, Governance, education, research, researcher, professional",
  },
  openData: {
    title: "National Multi-tenanted Repository | LIBSENSE",
    description:
      "National Multi-tenanted Repository  for organization or institutions",
    keywords:
      "Open Science, Weko3, Platform, Africa, Policy, Governance, research, researcher, professional",
  },
  pidsServices: {
    title: "Persistent Identifiers | LIBSENSE",
    description: "Persistent Identifiers for organization or institutions",
    keywords:
      "Open Science, Pids, ARKs, ARK, PidsLink, Africa, Policy, Governance, research, researcher, professional",
  },
  aboutUs: {
    title: "About Us | LIBSENSE",
    description:
      "Welcome to LIBSENSE, fostering open science policy and governance in Africa.",
    keywords:
      "Open Science, impact, collaboration, projects, initiatives, policy-observatory, ruforum, Africa, Policy, Governance",
  },
  initiatives: {
    title: "Project Initiatives | LIBSENSE",
    description:
      "Welcome to LIBSENSE, fostering open science policy and governance in Africa.",
    keywords:
      "Open Science,impact, collaboration, projects, initiatives, policy-observatory, ruforum, Africa, Policy, Governance, research, researcher, professional",
  },
  policyObservatory: {
    title: "Policy Observatory | LIBSENSE",
    description:
      "Welcome to LIBSENSE, fostering open science policy and governance in Africa.",
    keywords:
      "Open Science,impact, collaboration, projects, initiatives, policy-observatory, ruforum, Africa, Policy, Governance, research, researcher, professional",
  },
  collaboration: {
    title: "Collaborations | LIBSENSE",
    description:
      "Welcome to LIBSENSE, fostering open science policy and governance in Africa.",
    keywords:
      "Open Science,impact, collaboration, projects, initiatives, policy-observatory, ruforum, Africa, Policy, Governance, research, researcher, professional",
  },
  eeiflCollab: {
    title: "LIBSENSE-EIFL Collaboration | LIBSENSE",
    description:
      "Welcome to LIBSENSE, fostering open science policy and governance in Africa.",
    keywords:
      "Open Science,impact, collaboration, projects, initiatives, policy-observatory, ruforum, Africa, Policy, Governance, research, researcher, professional",
  },
  ruforumCollab: {
    title: "LIBSENSE-RUFORUM Collaboration | LIBSENSE",
    description:
      "Welcome to LIBSENSE, fostering open science policy and governance in Africa.",
    keywords:
      "Open Science,impact, collaboration, projects, initiatives, policy-observatory, ruforum, Africa, Policy, Governance, research, researcher, professional",
  },
  impacts: {
    title: "Impact| LIBSENSE",
    description:
      "Welcome to LIBSENSE, fostering open science policy and governance in Africa.",
    keywords:
      "Open Science,impact, collaboration, projects, initiatives, policy-observatory, ruforum, Africa, Policy, Governance, research, researcher, professional",
  },
  openScienceDetails: {
    title: "Open Science Policy & Governance | LIBSENSE",
    description:
      "Welcome to LIBSENSE, fostering open science policy and governance in Africa.",
    keywords:
      "Open Science,impact, collaboration, projects, initiatives, policy-observatory, ruforum, Africa, Policy, Governance, research, researcher, professional",
  },
  openScience: {
    title: "Open Science Policy & Governance | LIBSENSE",
    description:
      "Welcome to LIBSENSE, fostering open science policy and governance in Africa.",
    keywords:
      "Open Science,impact, collaboration, projects, initiatives, policy-observatory, ruforum, Africa, Policy, Governance, research, researcher, professional",
  },
  roadMap: {
    title: "Open Science Policy & Governance Roadmap | LIBSENSE",
    description:
      "Welcome to LIBSENSE, fostering open science policy and governance in Africa.",
    keywords:
      "Open Science,impact, collaboration, projects, initiatives, policy-observatory, ruforum, Africa, Policy, Governance, research, researcher, professional",
  },
  openScienceInfrastructure: {
    title: "Open Science Infrastructure | LIBSENSE",
    description:
      "Welcome to LIBSENSE, fostering open science policy and governance in Africa.",
    keywords:
      "Open Science,impact, collaboration, projects, initiatives, policy-observatory, ruforum, Africa, Policy, Governance, research, researcher, professional",
  },
  openScienceInfrastructureDetails: {
    title: "Open Science Infrastructure | LIBSENSE",
    description:
      "Welcome to LIBSENSE, fostering open science policy and governance in Africa.",
    keywords:
      "Open Science,impact, collaboration, projects, initiatives, policy-observatory, ruforum, Africa, Policy, Governance, research, researcher, professional",
  },
  capacityBuilding: {
    title: "Capacity Building | LIBSENSE",
    description:
      "Welcome to LIBSENSE, fostering open science policy and governance in Africa.",
    keywords:
      "Open Science,impact, collaboration, projects, initiatives, policy-observatory, ruforum, Africa, Policy, Governance, research, researcher, professional, capacity building capacity improvement",
  },
  capacityBuildingDetails: {
    title: "Capacity Building | LIBSENSE",
    description:
      "Welcome to LIBSENSE, fostering open science policy and governance in Africa.",
    keywords:
      "Open Science,impact, collaboration, projects, initiatives, policy-observatory, ruforum, Africa, Policy, Governance, research, researcher, professional, capacity building capacity improvement",
  },
  news: {
    title: "News | LIBSENSE",
    description: "News about open science policy in Africa.",
    keywords:
      "Open Science,impact, collaboration, projects, initiatives, policy-observatory, ruforum, Africa, Policy, Governance, research, researcher, professional, capacity building capacity improvement, current news, news, latest news, all news",
  },
  newsDetails: {
    title: "News | LIBSENSE",
    description: "News about open science policy in Africa.",
    keywords:
      "Open Science,impact, collaboration, projects, initiatives, policy-observatory, ruforum, Africa, Policy, Governance, research, researcher, professional, capacity building capacity improvement, current news, news, latest news, all news",
  },
  events: {
    title: "Events | LIBSENSE",
    description: "News about open science policy in Africa.",
    keywords:
      "Open Science,events, current events, all events, events, impact, collaboration, projects, initiatives, policy-observatory, ruforum, Africa, Policy, Governance, research, researcher, professional, capacity building capacity improvement",
  },
  eventsDetails: {
    title: "Events | LIBSENSE",
    description: "News about open science policy in Africa.",
    keywords:
      "Open Science,events, current events, all events, events, impact, collaboration, projects, initiatives, policy-observatory, ruforum, Africa, Policy, Governance, research, researcher, professional, capacity building capacity improvement",
  },
  networkOfExperts: {
    title: "Network of Experts | LIBSENSE",
    description: "network of experts in open science policy in Africa.",
    keywords:
      "Open Science, network of experts, experts, impact, collaboration, projects, initiatives, policy-observatory, ruforum, Africa, Policy, Governance, research, researcher, professional, capacity building capacity improvement",
  },
  ECR: {
    title: "ECR | LIBSENSE",
    description: "network of experts in open science policy in Africa.",
    keywords:
      "Open Science, ghana, ecr, nigeria, west africa, network of experts, experts, impact, collaboration, projects, initiatives, policy-observatory, ruforum, Africa, Policy, Governance, research, researcher, professional, capacity building capacity improvement",
  },
  ECRGhana: {
    title: "ECR Nigeria| LIBSENSE",
    description: "network of experts in open science policy in Africa.",
    keywords:
      "Open Science, ghana, ecr, nigeria, west africa, network of experts, experts, impact, collaboration, projects, initiatives, policy-observatory, ruforum, Africa, Policy, Governance, research, researcher, professional, capacity building capacity improvement",
  },
  ECRNigeria: {
    title: "ECR Nigeria| LIBSENSE",
    description: "network of experts in open science policy in Africa.",
    keywords:
      "Open Science, ghana, ecr, nigeria, west africa, network of experts, experts, impact, collaboration, projects, initiatives, policy-observatory, ruforum, Africa, Policy, Governance, research, researcher, professional, capacity building capacity improvement",
  },
  Ruforum: {
    title: "Ruforum | LIBSENSE",
    description: "ruforum in open science policy in Africa.",
    keywords:
      "Open Science, ghana, ecr, nigeria, west africa, ruforum, experts, impact, collaboration, projects, initiatives, policy-observatory, ruforum, Africa, Policy, Governance, research, researcher, professional, capacity building capacity improvement",
  },
  resources: {
    title: "Resources | LIBSENSE",
    description: "Resources about open science policy in Africa.",
    keywords:
      "Open Science, resources, Africa, Policy, Governance, education, research, researcher, professional",
  },
  contactUs: {
    title: "Contact Us | LIBSENSE",
    description: "More details about open science policy in Africa.",
    keywords:
      "Open Science, contact, Africa, Policy, Governance, research, researcher, professional",
  },
};

// Scroll to Top Component
const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    const mainContent = document.getElementById("main-content");
    if (mainContent) mainContent.scrollTo(0, 0);
  }, [pathname]);
  return null;
};

const App: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  }, []);

  useEffect(() => {
    ReactGA.send("pageview");
  }, []);

  if (loading) return <Loader />;

  return (
    <div className="flex h-screen w-full overflow-hidden">
      <Router>
        <div className="flex grow flex-col content-start overflow-hidden bg-background">
          <Navbar />
          <div
            id="main-content"
            className="scrollbar-thumb-rounded scrollbar-track-rounded grow overflow-y-auto overflow-x-hidden scrollbar-thin scrollbar-track-transparent scrollbar-thumb-muted"
          >
            <ScrollToTop />
            <ScrollToTopButton />
            <Routes>
              {/* Dynamic Redirects */}
              {redirects.map(({ from, to }, index) => (
                <Route
                  key={index}
                  path={from}
                  element={<Navigate to={to} replace />}
                />
              ))}

              {/* Routes with Metadata */}
              <Route
                path="/home"
                element={
                  <>
                    <PageTitle {...pageMetadata.home} />
                    <Home />
                  </>
                }
              />
              <Route
                path="/home/shared-repositories"
                element={
                  <>
                    <PageTitle {...pageMetadata.sharedRegional} />
                    <SharedRepo />
                  </>
                }
              />
              <Route
                path="/home/publishing-platforms"
                element={
                  <>
                    <PageTitle {...pageMetadata.publishService} />
                    <SharedPublishing />
                  </>
                }
              />
              <Route
                path="/home/open-data"
                element={
                  <>
                    <PageTitle {...pageMetadata.openData} />
                    <OpenData />
                  </>
                }
              />
              <Route
                path="/home/pids-service"
                element={
                  <>
                    <PageTitle {...pageMetadata.pidsServices} />
                    <PIDsService />
                  </>
                }
              />
              <Route
                path="/about-us"
                element={
                  <>
                    <PageTitle {...pageMetadata.aboutUs} />
                    <AboutUs />
                  </>
                }
              />
              <Route
                path="/about-us/project-initiatives"
                element={
                  <>
                    <PageTitle {...pageMetadata.initiatives} />

                    <ProjectsInitiative />
                  </>
                }
              />
              <Route
                path="/about-us/policy-observatory"
                element={
                  <>
                    <PageTitle {...pageMetadata.policyObservatory} />
                    <PolicyObservatory />
                  </>
                }
              />
              <Route
                path="/about-us/collaborations"
                element={
                  <>
                    <PageTitle {...pageMetadata.collaboration} />
                    <Collaborations />
                  </>
                }
              />
              <Route
                path="/about-us/collaborations/eeifl"
                element={
                  <>
                    <PageTitle {...pageMetadata.eeiflCollab} />
                    <LIBSENSEEIFL />
                  </>
                }
              />
              <Route
                path="/about-us/collaborations/ruforum"
                element={
                  <>
                    <PageTitle {...pageMetadata.ruforumCollab} />
                    <RuforumPage />
                  </>
                }
              />
              <Route
                path="/about-us/impact"
                element={
                  <>
                    <PageTitle {...pageMetadata.impacts} />

                    <ImpactSection />
                  </>
                }
              />
              <Route
                path="/what-we-do/open-science-policy/:policyType"
                element={
                  <>
                    <PageTitle {...pageMetadata.openScienceDetails} />

                    <OpenPolicyPage />
                  </>
                }
              />
              <Route
                path="/what-we-do/open-science-policy"
                element={
                  <>
                    <PageTitle {...pageMetadata.openScience} />

                    <OpenPolicyPage />
                  </>
                }
              />
              <Route
                path="/what-we-do/open-science-policy/roadmaps"
                element={
                  <>
                    <PageTitle {...pageMetadata.roadMap} />

                    <RoadmapPage />
                  </>
                }
              />
              <Route
                path="/what-we-do/open-infrastructure/:policyType"
                element={
                  <>
                    <PageTitle
                      {...pageMetadata.openScienceInfrastructureDetails}
                    />

                    <OpenAccessPage />
                  </>
                }
              />
              <Route
                path="/what-we-do/open-infrastructure"
                element={
                  <>
                    <PageTitle {...pageMetadata.openScienceInfrastructure} />
                    <OpenAccessPage />
                  </>
                }
              />
              <Route
                path="/what-we-do/capacity-building/:policyType"
                element={
                  <>
                    <PageTitle {...pageMetadata.capacityBuildingDetails} />
                    <CapacityBuildingPage />
                  </>
                }
              />
              <Route
                path="/what-we-do/capacity-building"
                element={
                  <>
                    <PageTitle {...pageMetadata.capacityBuilding} />

                    <CapacityBuildingPage />
                  </>
                }
              />
              <Route
                path="/media/news"
                element={
                  <>
                    <PageTitle {...pageMetadata.news} />

                    <NewsSection />
                  </>
                }
              />
              <Route
                path="/news/:id"
                element={
                  <>
                    <PageTitle {...pageMetadata.newsDetails} />

                    <NewsDetail />
                  </>
                }
              />
              <Route
                path="/media/events"
                element={
                  <>
                    <PageTitle {...pageMetadata.events} />

                    <EventSection />
                  </>
                }
              />
              <Route
                path="/event/:id"
                element={
                  <>
                    <PageTitle {...pageMetadata.eventsDetails} />

                    <EventDetail />
                  </>
                }
              />
              <Route
                path="/resources"
                element={
                  <>
                    <PageTitle {...pageMetadata.resources} />
                    <Resources />
                  </>
                }
              />
              <Route
                path="/contact-us"
                element={
                  <>
                    <PageTitle {...pageMetadata.contactUs} />
                    <ContactUs />
                  </>
                }
              />

              <Route
                path="/network-of-experts"
                element={
                  <>
                    <PageTitle {...pageMetadata.networkOfExperts} />

                    <NetworkOfExperts />
                  </>
                }
              />
              <Route
                path="/ecr"
                element={
                  <>
                    <PageTitle {...pageMetadata.ECR} />
                    <RDMPage />
                  </>
                }
              />

              <Route
                path="/ecr-ghana"
                element={
                  <>
                    <PageTitle {...pageMetadata.ECRGhana} />
                    <ECRGhanaPage />
                  </>
                }
              />

              <Route
                path="/ecr-nigeria"
                element={
                  <>
                    <PageTitle {...pageMetadata.ECRNigeria} />
                    <ECRNigeriaPage />
                  </>
                }
              />
              <Route path="/open-science/:title" element={<WorkshopDetail />} />
              <Route
                path="/ruforum"
                element={
                  <>
                    <PageTitle {...pageMetadata.Ruforum} />
                    <RuforumPage />
                  </>
                }
              />

              <Route
                path="/coppha"
                element={
                  <>
                    <PageTitle {...pageMetadata.Ruforum} />
                    <CopphaPage />
                  </>
                }
              />

              {/* Add other routes dynamically */}
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
            <Footer />
          </div>
        </div>
      </Router>
    </div>
  );
};

export default App;
