import React from "react";
import { Link, NavLink } from "react-router-dom";
import { FaTwitter } from "react-icons/fa";
import { useTheme } from "../../context/ThemeContext";
import ChatDialog from "../Chat/ChatDialog";

const Footer: React.FC = () => {
  const year = new Date().getFullYear();

  // const [isChatOpen, setIsChatOpen] = useState(false);

  const { theme } = useTheme();
  const img =
    theme.mode === "light"
      ? "/assets/images/libsense.png"
      : "/assets/images/logo-dark.png";

  const afr3 =
    theme.mode === "light"
      ? "/assets/images/AC3-logo.png"
      : "/assets/images/Afr3-dark.png";

  const eu =
    theme.mode === "light"
      ? "/assets/images/EU-logo.png"
      : "/assets/images/dark.png";

  return (
    <footer className="bg-card text-foreground">
      <div className="flex flex-col items-center justify-center min-w-[18rem] w-full bg-card p-4">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8 w-full max-w-7xl">
          <div className="flex flex-col items-start">
            <NavLink
              to="/"
              aria-label="Home"
              className="flex items-center mb-4"
            >
              <img src={`${img}`} className="h-[120px]" alt="Libsense Logo" />
            </NavLink>
            <ul className="space-y-1">
              {/* <li className="text-muted-foreground">
                <NavLink
                  to="/privacy"
                  className={({ isActive }) =>
                    isActive
                      ? "text-primary inline-flex  text-xs font-medium"
                      : "text-muted-foreground/50 hover:text-muted-foreground inline-flex  text-xs font-medium"
                  }
                >
                  Privacy
                </NavLink>
              </li> */}
              {/* <li className="text-muted-foreground">
                <NavLink
                  to="/disclaimer"
                  className={({ isActive }) =>
                    isActive
                      ? "text-primary inline-flex  text-xs font-medium"
                      : "text-muted-foreground/50 hover:text-muted-foreground inline-flex  text-xs font-medium"
                  }
                >
                  Disclaimer
                </NavLink>
              </li> */}
            </ul>
          </div>

          <div className="flex flex-col">
            <h2 className="text-muted-foreground font-semibold">
              About LIBSENSE
            </h2>
            <ul className="flex flex-col space-y-1 mt-2">
              <li className="text-muted-foreground/50 hover:text-muted-foreground inline-flex  text-xs font-medium">
                LIBSENSE is a pan-African, community-led initiative to
                strengthen open science in Africa
              </li>
            </ul>
          </div>

          <div className="flex flex-col">
            <h2 className="text-muted-foreground font-semibold">
              Get in touch
            </h2>
            <ul className="flex flex-col space-y-1 mt-2">
              <li className="text-muted-foreground/50 hover:text-muted-foreground inline-flex  text-xs font-medium">
                West and Central African Research and Education Network,
              </li>
              <li className="text-muted-foreground/50 hover:text-muted-foreground inline-flex  text-xs font-medium">
                VCG Office Complex, IPS Road
              </li>
              <li className="text-muted-foreground/50 hover:text-muted-foreground inline-flex  text-xs font-medium">
                P O Box LG 1279,
              </li>
              <li className="text-muted-foreground/50 hover:text-muted-foreground inline-flex  text-xs font-medium">
                Accra, Ghana
              </li>
              <li className="text-muted-foreground/50 hover:text-muted-foreground inline-flex  text-xs font-medium">
                Tel: +233 302942873
              </li>
            </ul>
          </div>

          <div className="flex flex-col">
            <h2 className="text-muted-foreground font-semibold">
              Important links
            </h2>
            <ul className="flex flex-col space-y-1 mt-2">
              <li className="text-muted-foreground">
                <NavLink
                  to="/about-us"
                  className={({ isActive }) =>
                    isActive
                      ? "text-primary inline-flex  text-xs font-medium"
                      : "text-muted-foreground/50 hover:text-muted-foreground inline-flex  text-xs font-medium"
                  }
                >
                  About LIBSENSE
                </NavLink>
              </li>
              <li className="text-muted-foreground">
                <NavLink
                  to="/media/news"
                  className={({ isActive }) =>
                    isActive
                      ? "text-primary inline-flex  text-xs font-medium"
                      : "text-muted-foreground/50 hover:text-muted-foreground inline-flex  text-xs font-medium"
                  }
                >
                  Latest news
                </NavLink>
              </li>
              <li className="text-muted-foreground">
                <NavLink
                  to="/media/events"
                  className={({ isActive }) =>
                    isActive
                      ? "text-primary inline-flex  text-xs font-medium"
                      : "text-muted-foreground/50 hover:text-muted-foreground inline-flex  text-xs font-medium"
                  }
                >
                  Upcoming events
                </NavLink>
              </li>
              {/* <li className="text-muted-foreground">
                <NavLink
                  to="/"
                  className={({ isActive }) =>
                    isActive
                      ? "text-primary inline-flex  text-xs font-medium"
                      : "text-muted-foreground/50 hover:text-muted-foreground inline-flex  text-xs font-medium"
                  }
                >
                  Get involved
                </NavLink>
              </li> */}
            </ul>
          </div>
        </div>

        <div className="flex flex-wrap justify-center items-center gap-4 mt-2 pb-4 w-full max-w-7xl">
          <div className="flex flex-col">
            <p className="text-muted-foreground/50 hover:text-muted-foreground inline-flex pb-1 text-xs font-medium">
              The LIBSENSE initiative is supported
            </p>
            <p className="text-muted-foreground/50 hover:text-muted-foreground inline-flex text-xs font-medium">
              by the AfricaConnect project
            </p>
          </div>

          <img src={afr3} alt="AC3 Project Logo" className="h-12" />
          <img src={eu} alt="EC Logo" className="h-12" />
          <div className="ml-auto flex gap-2">
            <Link
              to="https://x.com/LIBSENSE"
              target="_blank"
              aria-label="Twitter"
            >
              <FaTwitter className="text-xxxl text-muted-foreground mr-2" />
            </Link>
          </div>
        </div>

        <div className="mt-4 text-center text-muted-foreground">
          Copyright {year}. LIBSENSE. All rights reserved
        </div>
      </div>
      <ChatDialog />
    </footer>
  );
};

export default Footer;
