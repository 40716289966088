import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const logos = [
  {
    url: "/assets/images/coppha/AFREHEALTH.jpg",
    website: "https://www.afrehealth.org/",
  },
  {
    url: "/assets/images/coppha/images.jpg",
    website: "https://peoples-praxis.org/",
  },
  {
    url: "/assets/images/coppha/PublicHealth.Africa-PHA.jpg",
    website: "https://www.publichealth.africa/",
  },
  {
    url: "/assets/images/coppha/logo-1.jpg",
    website: "https://publichealth-edu.org/",
  },
  {
    url: "/assets/images/coppha/AC3-logo.jpg",
    website: "https://africaconnect3.net",
  },
  {
    url: "/assets/images/coppha/Layer.jpg",
    website:
      "https://international-partnerships.ec.europa.eu/policies/programming/projects/africaconnect_en",
  },
  {
    url: "/assets/images/coppha/LIBSENSE-logo.jpg",
    website: "/",
  },
  {
    url: "/assets/images/coppha/WACREN-LOGO.jpg",
    website: "https://wacren.net/en/",
  },
];

const CollaboratingCarousel: React.FC = () => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 3000,
    cssEase: "linear",
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          autoplay: true,
          arrows: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 2,
          autoplay: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: true,
        },
      },
    ],
  };

  const handleClick = (web: string) => {
    window.open(web, "_blank");
  };

  return (
    <div>
      <Slider {...settings}>
        {logos.map((logo, index) => (
          <div
            key={index}
            className="flex cursor-pointer justify-center items-center p-4"
            onClick={() => handleClick(logo.website)}
          >
            <img
              src={logo.url}
              alt={`Logo ${index + 1}`}
              className="w-full h-16 object-contain bg-background"
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default CollaboratingCarousel;
