// src/components/WorkingGroupForm.tsx
import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { countriesWithLabelValue } from "../../utils/countries";
import { BASE_API_URL } from "../../utils/constants";

interface FormData {
  title: string;
  firstName: string;
  surname: string;
  email: string;
  gender: string;
  institution: string;
  country: string;
  profile: File | null;
  publications: string;
  contributions: string;
}

// Validation schema using Yup
const validationSchema = Yup.object({
  title: Yup.string().required("Title is required"),
  firstName: Yup.string().required("First Name is required"),
  surname: Yup.string().required("Surname is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  gender: Yup.string().required("Gender is required"),
  institution: Yup.string().required("Institution is required"),
  country: Yup.string().required("Country is required"),
  publications: Yup.string().url("Invalid URL"),
  contributions: Yup.string().required("Please describe your contributions"),
});

interface MessageFormProps {
  onSuccess: () => void;
}

const JoinEcrForm: React.FC<MessageFormProps> = ({ onSuccess }) => {
  const [loading, setLoading] = useState(false);

  const initialValues: FormData = {
    title: "",
    firstName: "",
    surname: "",
    email: "",
    gender: "",
    institution: "",
    country: "",
    profile: null,
    publications: "",
    contributions: "",
  };

  const handleSubmit = async (values: FormData) => {
    setLoading(true);
    const formDataToSend = new FormData();
    Object.entries(values).forEach(([key, value]) => {
      if (key === "profile" && value) {
        formDataToSend.append(key, value);
      } else {
        formDataToSend.append(key, value as string);
      }
    });

    try {
      const response = await axios.post(
        `${BASE_API_URL}libsense/apply-for-ecr-team`,
        formDataToSend,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      setLoading(false);
      alert(response.data.message);
      onSuccess();
    } catch (error: any) {
      const err =
        error?.response?.data?.error ||
        "Form submission failed.Try again after sometime";
      setLoading(false);
      alert(err);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      {({ setFieldValue }) => (
        <>
          <h2 className="text-foreground mb-2 text-lg text-center">
            Join Our ECR Team
          </h2>
          <Form className="mx-auto p-4 h-96 overflow-y-scroll">
            <div className="mb-4">
              <label
                htmlFor="title"
                className="block text-sm font-medium text-foreground"
              >
                Title
              </label>
              <Field
                as="select"
                name="title"
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
              >
                <option value="">Select Title</option>
                <option value="Mr.">Mr.</option>
                <option value="Mrs.">Mrs.</option>
                <option value="Miss.">Miss.</option>
                <option value="Dr.">Dr.</option>
                <option value="Prof.">Prof.</option>
              </Field>
              <ErrorMessage
                name="title"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-6">
              <div className="mb-4">
                <label
                  htmlFor="firstName"
                  className="block text-sm font-medium text-foreground"
                >
                  First Name
                </label>
                <Field
                  type="text"
                  name="firstName"
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                />
                <ErrorMessage
                  name="firstName"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="surname"
                  className="block text-sm font-medium text-foreground"
                >
                  Surname
                </label>
                <Field
                  type="text"
                  name="surname"
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                />
                <ErrorMessage
                  name="surname"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>
            </div>

            <div className="mb-4">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-foreground"
              >
                Email
              </label>
              <Field
                type="email"
                name="email"
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
              />
              <ErrorMessage
                name="email"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>

            <div className="mb-4">
              <label
                htmlFor="gender"
                className="block text-sm font-medium text-foreground"
              >
                Gender
              </label>
              <Field
                as="select"
                name="gender"
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
              >
                <option value="">Select your gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Prefer not to say">Prefer not to say</option>
              </Field>
              <ErrorMessage
                name="gender"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>

            <div className="mb-4">
              <label
                htmlFor="institution"
                className="block text-sm font-medium text-foreground"
              >
                Institution/Organisation
              </label>
              <Field
                type="text"
                name="institution"
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
              />
              <ErrorMessage
                name="institution"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>

            <div className="mb-4">
              <label
                htmlFor="country"
                className="block text-sm font-medium text-foreground"
              >
                Country
              </label>
              <Field
                as="select"
                name="country"
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
              >
                <option value="">Select Country</option>
                {countriesWithLabelValue.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Field>
              <ErrorMessage
                name="country"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>

            <div className="mb-4">
              <label
                htmlFor="profile"
                className="block text-sm font-medium text-foreground"
              >
                Upload your profile piture
              </label>
              <input
                type="file"
                name="profile"
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                onChange={(event) => {
                  if (event.currentTarget.files) {
                    setFieldValue("profile", event.currentTarget.files[0]);
                  }
                }}
              />

              {/* <div className="my-4">
<label className="mb-3 block text-black dark:text-white">
  Upload Image
</label>
<input
  type="file"
  onChange={(e) => setImage(e.target.files ? e.target.files[0] : null)}
  className="w-full cursor-pointer rounded-lg border-[1.5px] border-stroke bg-transparent outline-none transition file:mr-5 file:border-collapse file:cursor-pointer file:border-0 file:border-r file:border-solid file:border-stroke file:bg-whiter file:py-3 file:px-5 file:hover:bg-primary file:hover:bg-opacity-10 focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
/>
</div> */}

              <ErrorMessage
                name="profile"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>

            <div className="mb-4">
              <label
                htmlFor="publications"
                className="block text-sm font-medium text-foreground"
              >
                Link to your socials(e.g LinkedIn, ...)
              </label>
              <Field
                type="url"
                name="publications"
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
              />
              <ErrorMessage
                name="publications"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>

            <div className="mb-4">
              <label
                htmlFor="contributions"
                className="block text-sm font-medium text-foreground"
              >
                What contributions can you make in the ECR Team?
              </label>
              <Field
                as="textarea"
                name="contributions"
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
              />
              <ErrorMessage
                name="contributions"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>

            <button
              type="submit"
              disabled={loading}
              className="w-full bg-primary text-primary-foreground py-2 px-4 rounded-md hover:bg-primary-foreground transition duration-300"
            >
              {loading ? "Please wait ..." : "Submit"}
            </button>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default JoinEcrForm;
