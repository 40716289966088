import React, { useEffect } from "react";
import HeaderImage from "../components/HeaderImage";
import { Link } from "react-router-dom";

const Resources: React.FC = () => {
  useEffect(() => {
    const element = document.getElementById("resources");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <>
      <HeaderImage
        image="/assets/images/backgrounds/resource.jpg"
        alt="Resource Background"
      />
      <div className="container mx-auto my-14" id="resources">
        <h2 className="text-center text-xxl font-semibold text-foreground uppercase mb-4">
          Resources
        </h2>
        <p className="text-sm text-foreground">
          Welcome to the resources page which is integrated into BAOBAB. BAOBAB
          is a digital repository platform crafted to manage, preserve, and
          share research data, publications, and a wide range of digital assets.
          Designed to handle extensive data volumes, BAOBAB offers both
          flexibility and scalability, making it ideal for institutions aiming
          to establish a comprehensive digital archive. With customizable
          features to meet unique institutional needs, BAOBAB is an essential
          tool for creating and maintaining a resilient and accessible digital
          repository. Click{" "}
          <Link
            to="https://baobab.wacren.net/communities/libsense/records?q=&l=list&p=1&s=10&sort=newest"
            target="_blank"
            className="text-primary"
          >
            BAOBAB
          </Link>{" "}
          to access all LIBSENSE resources
        </p>
      </div>
    </>
  );
};

export default Resources;
