import React, { useEffect, useState } from "react";
import HeaderBanner from "../../components/Header";
import { Link } from "react-router-dom";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import JoinEcrForm from "./JoinEcrForm";

const ECRGhanaPage: React.FC = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedActivity, setSelectedActivity] = useState<any>(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const Team = [
    {
      name: "Dr. Dominic Agyei Dankwah",
      link: "https://www.linkedin.com/in/dominic-agyei-dankwah-phd-79943895/",
    },
    {
      name: "Dr. Antonia Bernadette Donkor",
      link: "https://www.linkedin.com/in/antonia-bernadette-donkor-615119b6",
    },
    {
      name: "Mr. George Clifford Yamson",
      link: "https://www.linkedin.com/in/george-clifford-yamson-484b6961?trk=contact-info",
    },
    {
      name: "Mr. Jonathan Seyram Agbamey",
      link: "https://www.linkedin.com/in/jonathan-agbamey-b35126100/",
    },
    {
      name: "Dr. Amy P. A. Asimah",
      link: "https://www.linkedin.com/in/amy-p-a-asimah-phd-a0801270/",
    },
    {
      name: "Mr. Amoaful Eric Amponsah",
      link: "https://www.linkedin.com/in/amoaful-eric-amponsah-t-a-70063055/",
    },
    {
      name: "Mr. Daniel Akwasi Afrane",
      link: "https://www.linkedin.com/search/results/all/?fetchDeterministicClustersOnly=true&heroEntityKey=urn%3Ali%3Afsd_profile%3AACoAAA5GBu8BMrTNKu_N5eK3-3L-AHAo-NxpWR8&keywords=daniel%20akwasi%20afrane&origin=RICH_QUERY_SUGGESTION&position=0&searchId=1f6cfb96-b1d0-4fea-b259-94c73be65ec1&sid=5d7&spellCorrectionEnabled=false",
    },
  ];

  const Activities = [
    {
      title: "Ghana ECR Team shares vision with Balme Library",
      thumbnail: "/assets/images/ecrgh/ecrgh1.jpeg",
      brief_description:
        "The Ghana Team grabbed the opportunity at a jointly organised train-the-trainer workshop in Accra to discuss the group’s aims and strategic objectives. Dr Dominic Agyei Dankwa highlighted the Ghana Team’s goal to promote collaboration with participating institutions as a crucial strategy for achieving its objectives.",
      full_description: [
        `<p className="text-sm text-foreground mb-4">The Ghana Team grabbed the opportunity at a jointly organised train-the-trainer workshop in Accra to discuss the group’s aims and strategic objectives. Dr Dominic Agyei Dankwa highlighted the Ghana Team’s goal to promote collaboration with participating institutions as a crucial strategy for achieving its objectives. The Team is committed to building the capacity of key stakeholders, proposing policy directions, and recommending appropriate infrastructure to support research data management.</p>`,
        `<p className="text-sm text-foreground mb-4">Following the workshop, the  <a
            href="https://photos.wacren.net/index.php?/category/110"
            target="_blank"
          >
            Team
          </a>  met with Dr. Theodosia S. A. Adanu, University Librarian of the Balme Library, University of Ghana, who shared her vision for the University to lead the research data management movement in the country. She highlighted her library’s commitment to research data management, including establishing a dedicated department and hiring an expert. Dr. Adanu praised the initiative and promised her support for the group.</p>`,
        `<p className="text-sm text-foreground mb-4">This event was a collaborative effort between ORCID, the Regional Maritime University (RMU), the Ghana Library Association (GLA) and the Consortium of Academic and Research Libraries in Ghana (CARLIGH).</p>`,
      ],
    },
  ];

  const openModal = (expert: any) => {
    setSelectedActivity(expert);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedActivity(null);
  };

  useEffect(() => {
    const element = document.getElementById("ecr-ghana");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <>
      <div>
        <HeaderBanner />
      </div>
      <div className="container mx-auto my-14" id="ecr-ghana">
        <section className="mb-14">
          <h2 className="text-center text-xxl font-semibold text-foreground uppercase mb-4">
            ECR Network Ghana
          </h2>
          <p className="text-sm text-foreground mb-2">
            The ECR Network Ghana is a group of early career researchers in
            Ghana who are passionate about promoting open science practices,
            including research data management, to enhance the quality, impact,
            and visibility of research in Ghana. Our goal is to create a
            community that supports collaboration, transparency, and reuse of
            research data, ultimately contributing to national development and
            global scientific progress. Furthermore, the group seeks to help
            empower Ghanaian researchers for a more open, collaborative, and
            impactful scientific community. Specifically, the group is poised
            to:
          </p>

          <ul className="list list-disc ml-6 text-sm text-foreground mb-4">
            <li>
              Promote awareness and adoption of open science practices among
              Ghanaian researchers
            </li>
            <li>Build capacity in research data management and open science</li>
            <li>Advocate for supportive policies and infrastructure</li>
            <li>
              Foster collaboration and networking among researchers and
              stakeholders
            </li>
            <li>
              Contribute to national and international open science initiatives
            </li>
            <li>
              Encourage researchers to make data findable, accessible,
              interoperable, and reusable
            </li>
            <li>
              Encourage responsible collaboration between Ghanaian and
              international scientists
            </li>
          </ul>

          <p className="text-sm text-foreground mb-4">
            The Group has, over the period, collaborated with the Regional
            Maritime University to{" "}
            <Link
              target="_blank"
              to="https://tinyurl.com/2kk77v3n"
              className="text-primary"
            >
              train librarians
            </Link>{" "}
            on the requisite knowledge and skills necessary to promote ORCID
            adoption in academic institutions across Ghana, to enhance the
            discoverability of research outputs and contribute to a more
            transparent and connected research ecosystem. Again, the group was
            very instrumental in drafting the{" "}
            <Link
              target="_blank"
              to="https://drive.google.com/file/d/1NIzWDl7wbnMNFtQ1LoBDDYUAhQVJJZ-m/view?usp=sharing"
              className="text-primary"
            >
              Research Data Management Workplan Template
            </Link>{" "}
            to guide institutions in adopting acceptable and globally relevant
            data management practices. Aside from these, the group has
            contributed to diverse and high-level consultative discussions,
            including{" "}
            <Link
              target="_blank"
              to="https://wacren2024.wacren.net/"
              className="text-primary"
            >
              WACREN
            </Link>{" "}
            2024 in Abuja, and{" "}
            <Link
              target="_blank"
              to="https://wacren.net/en/event/national-collaboration-and-capacity-building-in-research-data-management-rdmpractices-and-governance-in-ghana/"
              className="text-primary"
            >
              LIBSENSE RDM Workshop, Ghana
            </Link>{" "}
            .
          </p>

          <p className="text-sm text-foreground mb-4">
            The group believes in the strength of collaboration and has, over
            the years, initiated, supported and participated in partnerships
            with key stakeholders in the research ecosystem. Specifically,
            sustained relationships have been established with{" "}
            <Link
              target="_blank"
              to="https://wacren2024.wacren.net/"
              className="text-primary"
            >
              WACREN
            </Link>{" "}
            , and
            <Link target="_blank" to="/ecr-nigeria" className="text-primary">
              LIBSENSE ECR Network - Nigeria
            </Link>{" "}
            . The team is working hard to forge more relationships, especially
            with academic institutions in Ghana and beyond.
          </p>
        </section>

        {/* */}

        <section className="mb-8">
          <h3 className="text-lg font-semibold text-foreground mb-4 text-left">
            Team Members
          </h3>
          <ul className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {Team.map((expert, index) => (
              <li key={index}>
                {expert.link ? (
                  <Link
                    to={expert.link}
                    target="_blank"
                    className="text-sm text-primary"
                  >
                    {expert.name}
                  </Link>
                ) : (
                  <p className="text-sm text-foreground">{expert.name}</p>
                )}
              </li>
            ))}
          </ul>
        </section>

        <section className="mb-12">
          <h3 className="text-xl font-semibold text-foreground mb-4 text-left">
            Activities & Outcomes
          </h3>
          <div className="grid grid-cols-1 gap-8">
            {Activities.map((activity, index) => (
              <div
                key={index}
                className="flex flex-col lg:flex-row overflow-hidden"
              >
                {/* Image section */}
                <div className="w-full lg:w-1/2 flex-shrink-0">
                  <img
                    src={activity.thumbnail}
                    alt={activity.title}
                    className="w-full bg-gray-200 h-full bg-cover"
                  />
                </div>

                {/* Text section */}
                <div className="flex flex-col justify-center p-6 w-full lg:w-2/3">
                  <h4 className="text-sm font-medium mb-2 text-left text-foreground">
                    {activity.title}
                  </h4>
                  <p className="text-xs text-foreground mb-4 text-left">
                    {activity.brief_description}
                  </p>
                  <button
                    className="bg-primary text-primary-foreground py-2 px-4 rounded-md w-max text-left"
                    onClick={() => openModal(activity)}
                  >
                    Read More
                  </button>
                </div>
              </div>
            ))}
          </div>
        </section>

        <div className="flex items-center justify-center">
          <button
            onClick={toggleModal}
            type="button"
            className="block text-center border border-foreground mb-4 px-4 py-2 rounded-md text-foreground transition duration-300 hover:text-primary"
          >
            Apply to join LIBSENSE ECR
          </button>
        </div>

        {/* <div className="flex justify-center space-x-8 my-8">
          <Link
            to="https://photos.wacren.net/index.php?/category/110"
            target="_blank"
            className="block text-center border border-foreground mb-4 px-4 py-2 rounded-md text-foreground transition duration-300 hover:text-primary"
          >
            Photos
          </Link>
        </div> */}
      </div>

      {/* Modal */}
      {showModal && selectedActivity && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50 overflow-y-auto">
          <div className="bg-card w-11/12 md:w-2/3 lg:w-1/2 p-8 rounded-lg relative max-h-[80vh] overflow-y-auto">
            {/* Close Button */}
            <button
              onClick={closeModal}
              className="absolute top-4 right-4 text-xl font-bold text-red-500"
            >
              &times;
            </button>

            {/* Modal Content */}
            <div className="flex flex-col gap-4 md:flex-row">
              {/* Text Section */}
              <div className="w-full">
                {selectedActivity.full_description.map(
                  (paragraph: any, index: any) => (
                    <div
                      key={index}
                      className="text-sm text-foreground mb-4 news-content"
                      dangerouslySetInnerHTML={{ __html: paragraph }}
                    ></div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="bg-white rounded-lg p-6 max-w-md w-full relative">
            {/* Close button */}
            <button
              onClick={toggleModal}
              type="button"
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
            >
              <FontAwesomeIcon icon={faTimes} className="w-6 h-6" />
            </button>
            <JoinEcrForm onSuccess={toggleModal} />
          </div>
        </div>
      )}
    </>
  );
};

export default ECRGhanaPage;
