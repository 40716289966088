import React from "react";
import { Link } from "react-router-dom";

const NationalPage: React.FC = () => {
  return (
    <>
      <p className="text-sm text-foreground mb-4">
        LIBSENSE works with the National Institute of Informatics (NII) to
        extend the Invenio-based{" "}
        {/* <Link to="/open-data" target="_blank" className="text-primary"> */}
        WEKO3 repository
        {/* </Link>{" "} */} multitenancy open data repository service to
        institutions in African countries. WACREN, on behalf of LIBSENSE,{" "}
        <Link
          to="https://wacren.net/en/news/wacren-collaborates-nii-research-and-development-open-science-infrastructure/"
          target="_blank"
          className="text-primary"
        >
          signed an MoU with NII in 2023
        </Link>{" "}
        to collaborate on adapting WEKO3 to serve the African research
        community.{" "}
        <Link
          to="https://wacren.net/en/news/exploring-open-science-in-japan-nii-wacren-mou-workshop-at-the-national-institute-of-informatics/"
          target="_blank"
          className="text-primary"
        >
          A practical workshop on implementing WEKO3
        </Link>{" "}
        was held in Tokyo. This{" "}
        <Link
          to="https://baobab.wacren.net/records/t6d47-pje68"
          target="_blank"
          className="text-primary"
        >
          Workshop report
        </Link>{" "}
        elucidates the importance, requirements and options of a shared national
        infrastructure, and WACREN has opted for WEKO3. The service will be
        available soon.
      </p>
    </>
  );
};

export default NationalPage;
