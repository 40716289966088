import React, { useEffect, useState } from "react";
import HeaderBanner from "../../components/Header";
import { Link } from "react-router-dom";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import JoinEcrForm from "./JoinEcrForm";

const RDMPage: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);
  const Activitties = [
    {
      title: "Ghana",
      flag: "/assets/images/flags/ghana-flag.jpg",
      brief_description:
        "A significant outcome of the LIBSENSE RDM Workshop in Accra was the birth of the ECR Ghana Team and their ensuing activities.",
      link: "/ecr-ghana",
    },
    {
      title: "Nigeria",
      flag: "/assets/images/flags/nigeria.jpg",
      brief_description:
        "The Nigeria team was borne out of the maiden RDM Workshop in Lagos.",
      link: "/ecr-nigeria",
    },
  ];

  useEffect(() => {
    const element = document.getElementById("rdm");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <>
      <div>
        <HeaderBanner />
      </div>
      <div className="container mx-auto mt-14 mb-20" id="rdm">
        <section className="mb-14">
          <h2 className="text-center text-xxl font-semibold text-foreground uppercase mb-4">
            LIBSENSE Early Career Researchers (ECR) Initiative
            {/* LIBSENSE RDM Programme */}
          </h2>
          <p className="text-sm text-foreground mb-2">
            The LIBSENSE ECRs initiative aims to support the development and
            empowerment of early career researchers in Africa. The ECRs include
            postgraduate students, postdoctoral researchers, new faculty members
            and researchers, librarians, research administrators and other
            academics; they collaborate to advance institutional and national
            RDM policies and practices.
          </p>

          <p className="text-sm text-foreground mb-2">
            Focusing on best practices in RDM and research ethics, the
            initiative fosters the formation of ECR Advocacy Groups in different
            African countries. These groups are primarily early-career
            researchers, librarians, research administrators, and academics. The
            LIBSENSE ECR initiative promotes open science and responsible
            research processes to enhance research visibility, transparency, and
            quality across the African continent. ECR teams are springing in
            countries across the WACREN region, including Ghana and Nigeria.
          </p>
        </section>

        <div className="flex items-center justify-center">
          <button
            onClick={toggleModal}
            type="button"
            className="block text-center border border-foreground mb-4 px-4 py-2 rounded-md text-foreground transition duration-300 hover:text-primary"
          >
            Apply to join LIBSENSE ECR
          </button>
        </div>

        <section className="mb-14">
          <h3 className="text-xl font-semibold text-foreground mb-4 text-left">
            Activities
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {Activitties.map((activity, index) => (
              <div
                key={index}
                className="flex flex-col lg:flex-row bg-card shadow-md rounded-md overflow-hidden"
              >
                {/* Image section */}
                <div className="w-full lg:w-1/2 flex-shrink-0">
                  <img
                    className="w-full h-full bg-cover"
                    src={activity.flag}
                    alt={`profile of ${activity.title}`}
                  />
                </div>

                {/* Text section */}
                <div className="flex flex-col justify-center p-6 w-full lg:w-2/3">
                  <h4 className="text-sm font-medium mb-2 text-left text-foreground">
                    {activity.title}
                  </h4>
                  <p className="text-xs text-foreground mb-4 text-left">
                    {activity.brief_description}
                  </p>
                  <Link
                    to={activity.link}
                    className="bg-primary text-primary-foreground py-2 px-4 rounded-md w-max text-left"
                  >
                    Read More
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="bg-white rounded-lg p-6 max-w-md w-full relative">
            {/* Close button */}
            <button
              onClick={toggleModal}
              type="button"
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
            >
              <FontAwesomeIcon icon={faTimes} className="w-6 h-6" />
            </button>
            <JoinEcrForm onSuccess={toggleModal} />
          </div>
        </div>
      )}
    </>
  );
};

export default RDMPage;
