import React, { useEffect, useState } from "react";
import HeaderBanner from "../../components/Header";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import JoinEcrForm from "./JoinEcrForm";

const ECRNigeriaPage: React.FC = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedActivity, setSelectedActivity] = useState<any>(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const Team = [
    {
      name: "Dr Fatimah Abduldayan",
      university: "Federal University of Technology, Minna",
      link: "https://www.linkedin.com/in/fatimahjibrilabduldayan/",
    },
    {
      name: " Dr Opeyemi Quadir",
      university: "Ahmadu Bello University, Zaria",
      link: "https://www.linkedin.com/in/opeyemi-quadir-a670a7212/",
    },
    {
      name: "Dr Sadiat Adetoro Salau",
      university: "Federal University of Technology, Minna",
      link: "https://www.linkedin.com/in/sadiat-adetoro-salau/",
    },
    {
      name: "Mrs Benedicta Uchendu",
      university: "Ignatius Ajuru University of Education",
      link: "https://www.linkedin.com/in/benedicta-uchendu-619843147/",
    },
    {
      name: "Dr Basiru Adetomiwa",
      university: "Redeemers University, Ede",
      link: "https://www.linkedin.com/in/basiru-adetomiwa-31b558150/",
    },
    {
      name: "Dr Sophia Adeyeye",
      university: "Lead City University, Ibadan",
      link: "https://www.linkedin.com/in/sophia-adeyeye-92a621a0/",
    },
    {
      name: "Mrs Ify Obim",
      university: "University of Nigeria, Nsukka",
      link: "https://www.linkedin.com/in/ify-obim-85883772/",
    },
    {
      name: "Mrs Tihyaunin Dang",
      university: "University of Jos",
    },
    {
      name: "Dr Taiwo Ajayi",
      university: "Lagos State University of Science & Technology",
    },
  ];

  const Activities = [
    {
      title: "ECR Nigeria holds open science advocacy workshop at NIA 2024",
      thumbnail: "/assets/images/ecrnaija/ecr1.jpg",
      brief_description:
        "The LIBSENSE ECR Network in Nigeria, organised and co-located an Open Science Advocacy and Engagement event within the Nigerian Library Association (NLA) National Conference on Wednesday, July 10 2024, at the CBN Centre of Excellence, University of Port Harcourt, Rivers State Nigeria.",
      full_description: [
        "The event was strategically scheduled alongside the NLA national conference to leverage the presence of a large gathering of librarians from across the country. The primary aim was to advocate for and create awareness about open science practices among librarians who play a key role in disseminating scientific knowledge.",
        "The event featured a presentation of findings from data collected on Open Science perception and practices among librarians in Nigeria. An engaging discussion with colleagues followed this to explore ways to enhance the adoption of open science principles personally and in their various institutions. The event also identified critical areas for improvement and action, setting the stage for future events promoting open science in Nigeria.",
        "This event was a collaborative effort between ORCID, the Regional Maritime University (RMU), the Ghana Library Association (GLA) and the Consortium of Academic and Research Libraries in Ghana (CARLIGH).",
        `
        <p>
            The event also allowed the team to showcase some of their activities
            and outputs- the common repository
            <a
              href="https://docs.google.com/spreadsheets/d/14DftpDbWuhe81Vr0VUZCpWLfR6kwCzjl/edit"
              target="_blank"
              style="color: blue;" rel="noreferrer"
            >
              metadata schema
            </a>
            , call to
            <a
              href="https://zenodo.org/records/10710011 "
              target="_blank"
               style="color: blue;" rel="noreferrer"
            >
              action on assessment systems
            </a>
            , and work plans derived from a research data management
            <a
              href="https://indico.wacren.net/event/186/"
              target="_blank"
               rel="noreferrer"
               style="color: blue;"
            >
              (RDM) workshop
            </a>
            in Nigeria as well as activities of the LIBSENSE Early Career
            Researchers (ECR) Network at the national level and within their
            various campuses. The other members of the group include Dr Sadiat
            Adetoro, Dr Basiru Adetomiwa, Dr Sophia Adeyeye, Dr Taiwo Ajayi, Dr
            Romoke Quadri, Mrs Benedicta Uchendu, Mrs Obim Evangel, and Mrs
            Tihuainin Dang.
          </p>
        `,
        `
        <p>
         <a
              href="https://drive.wacren.net/index.php/s/4Y69H7LsKd6NoS7"
              target="_blank"
               rel="noreferrer"
               style="color: blue;"
            >
              Download presentation here
            </a>
        </p>
        `,
      ],
    },
  ];

  const openModal = (expert: any) => {
    setSelectedActivity(expert);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedActivity(null);
  };

  useEffect(() => {
    const element = document.getElementById("ecr-nigeria");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <>
      <div>
        <HeaderBanner />
      </div>
      <div className="container mx-auto " id="ecr-nigeria">
        <section className="py-8">
          <h2 className="text-center text-xxl font-semibold text-foreground uppercase mb-4">
            ECR Network Nigeria
          </h2>
          <p className="text-sm text-foreground mt-4 mb-2">
            The Early Career Researchers (ECRs) team is an inclusive,
            community-led, Nigerian open science advocacy and implementation
            group championing discussions and engagements around open science
            with key national and institutional stakeholders.
          </p>

          <p className="text-sm text-foreground mb-2">
            The group, in collaboration with the Association of University
            Librarians of Nigerian Universities and the LIBSENSE network of
            experts, have come out with several outputs, such as a new common
            repository{" "}
            <Link
              className="text-primary"
              to="https://docs.google.com/spreadsheets/d/14DftpDbWuhe81Vr0VUZCpWLfR6kwCzjl/edit?gid=1808480562#gid=1808480562"
              target="_blank"
            >
              metadata schema
            </Link>
            , a call to{" "}
            <Link
              to="https://zenodo.org/records/10710011"
              target="_blank"
              className="text-primary"
            >
              action on assessment systems
            </Link>{" "}
            and work plans derived from a recent{" "}
            <Link
              to="https://indico.wacren.net/event/186/"
              target="_blank"
              className="text-primary"
            >
              research data management (RDM) workshop
            </Link>
            . We presented these{" "}
            <Link
              to="https://indico.wacren.net/event/195/attachments/635/861/Model%20Open%20Science%20Policy%20of%20Nigeria.pdf"
              target="_blank"
              className="text-primary"
            >
              outputs
            </Link>{" "}
            at priority-setting leadership engagement meetings in Lagos and
            Abuja.
          </p>
          <p className="text-sm text-foreground mb-2">
            Open Science-related activites for faculty members and postgraduate
            students are held on our various campuses.
          </p>
        </section>
        {/**/}

        <section className="mb-8">
          <h3 className="text-lg font-semibold text-foreground mb-4 text-left">
            Team Members
          </h3>
          <ul className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {Team.map((expert, index) => (
              <li key={index}>
                {expert.link ? (
                  <Link
                    to={expert.link}
                    target="_blank"
                    className="text-sm text-primary"
                  >
                    {expert.name}
                  </Link>
                ) : (
                  <p className="text-sm text-foreground">{expert.name}</p>
                )}

                <p className="text-xs text-foreground">{expert.university}</p>
              </li>
            ))}
          </ul>
        </section>

        <section className="mb-12">
          <h3 className="text-xl font-semibold text-foreground mb-4 text-left">
            Activities & Outcomes
          </h3>
          <div className="grid grid-cols-1 gap-8">
            {Activities.map((activity: any, index) => (
              <div
                key={index}
                className="flex flex-col lg:flex-row overflow-hidden"
              >
                {/* Image section */}
                <div className="w-full lg:w-1/2 flex-shrink-0">
                  <img
                    src={activity.thumbnail}
                    alt={activity.title}
                    className="w-full bg-gray-200 h-full bg-cover"
                  />
                </div>

                {/* Text section */}
                <div className="flex flex-col justify-center p-6 w-full lg:w-2/3">
                  <h4 className="text-sm font-medium mb-2 text-left text-foreground">
                    {activity.title}
                  </h4>
                  <p className="text-xs text-foreground mb-4 text-left">
                    {activity.brief_description}
                  </p>
                  <button
                    className="bg-primary text-primary-foreground py-2 px-4 rounded-md w-max text-left"
                    onClick={() => openModal(activity)}
                  >
                    Read More
                  </button>
                </div>
              </div>
            ))}
          </div>
        </section>

        <div className="flex items-center justify-center">
          <button
            onClick={toggleModal}
            type="button"
            className="block text-center border border-foreground mb-4 px-4 py-2 rounded-md text-foreground transition duration-300 hover:text-primary"
          >
            Apply to join LIBSENSE ECR
          </button>
        </div>

        <div className="flex justify-around space-x-8 my-8">
          <Link
            to="https://photos.wacren.net/index.php?/category/76"
            target="_blank"
            className="block text-center border border-foreground mb-4 px-4 py-2 rounded-md text-foreground transition duration-300 hover:text-primary"
          >
            Photos
          </Link>
        </div>
      </div>

      {showModal && selectedActivity && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50 overflow-y-auto">
          <div className="bg-card w-11/12 md:w-2/3 lg:w-1/2 p-8 rounded-lg relative max-h-[80vh] overflow-y-auto">
            {/* Close Button */}
            <button
              onClick={closeModal}
              className="absolute top-4 right-4 text-xl font-bold text-red-500"
            >
              &times;
            </button>

            {/* Modal Content */}
            <div className="flex flex-col gap-4 md:flex-row">
              {/* Text Section */}
              <div className="w-full">
                {selectedActivity.full_description.map(
                  (paragraph: any, index: any) => (
                    <p
                      key={index}
                      className="text-sm text-foreground mb-4"
                      dangerouslySetInnerHTML={{ __html: paragraph }}
                    ></p>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="bg-white rounded-lg p-6 max-w-md w-full relative">
            {/* Close button */}
            <button
              onClick={toggleModal}
              type="button"
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
            >
              <FontAwesomeIcon icon={faTimes} className="w-6 h-6" />
            </button>
            <JoinEcrForm onSuccess={toggleModal} />
          </div>
        </div>
      )}
    </>
  );
};

export default ECRNigeriaPage;
