import React, { useEffect } from "react";
import ButtonGroup from "../../components/InvolvedBtn";
import GroupsButton from "../../components/GroupsBtn";
import HeaderImage from "../../components/HeaderImage";
import AboutTimeline from "./Timeline";
import { Link, useLocation } from "react-router-dom";

const AboutUs: React.FC = () => {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const element = document.getElementById(hash.replace("#", ""));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [hash]);

  return (
    <>
      <HeaderImage image="/assets/images/backgrounds/about.jpg" />

      <div className="container mx-auto my-14" id="story">
        <h2 className="text-center text-xxl font-semibold text-foreground uppercase mb-4">
          The LIBSENSE Story
        </h2>

        <p className="text-sm text-foreground mt-4">
          LIBSENSE (Library Support for Embedded NREN Services and
          E-infrastructure) is a{" "}
          <span className="cursor-pointer font-medium text-primary">
            WACREN
          </span>
          -initiated, community-driven, pan-African programme aimed at building
          a community of practice for open science and progressing the adoption
          of open science policies, services and infrastructures in Africa. Open
          science is an international trend that makes research outputs
          accessible, visible, and reusable. This phenomenon is transforming the
          way research is done and increasing its value and impact by
          accelerating the pace of scientific discoveries and expanding the
          adoption of research outcomes for everyone.
        </p>

        <p className="text-sm text-foreground my-2">
          Since its initiation in 2016, LIBSENSE has been strengthening open
          science in Africa by leveraging the capacities and capabilities of
          research and education networks (RENs), academic libraries and
          research communities within the continent. LIBSENSE provides an avenue
          through which different stakeholder communities collaborate to define
          priority activities, share knowledge, and develop relevant open
          science and scholarly communication services.
        </p>
        <p className="text-sm text-foreground my-2">
          LIBSENSE adopts bottom-up, distributed, and heterogeneous methods to
          foster open science. The programme is all-inclusive, as demonstrated
          by its communities' regional dispersal, the languages of operation,
          and the cultural nature of its operations and communities.
        </p>
      </div>

      <div className="text-center">
        <h2 className="text-center text-xxl font-semibold text-foreground uppercase mb-4">
          The Journey in Time
        </h2>

        <div className="mt-2 mb-4">
          <AboutTimeline />
        </div>

        <section className="mt-4 text-foreground">
          <p className="text-sm text-forebackground">
            Download LIBSENSE Impact Report:{"  "}
            <Link
              to="https://baobab.wacren.net/records/kym89-1p308"
              target="_blank"
              className="text-primary"
            >
              Impact Evidence and Stakeholders' Testimonials from 2016-2024
            </Link>
          </p>
        </section>
        {/* <MissionsButton /> */}
      </div>

      <div className="bg-card py-1 mt-6">
        <GroupsButton />
      </div>

      <ButtonGroup />
    </>
  );
};

export default AboutUs;
