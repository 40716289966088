import React, { useState } from "react";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import ButtonGroup from "../components/InvolvedBtn";
import LogoCarousel from "../components/LogoCarousel";
// import Stats from "../components/Stats";
import HeaderBanner from "../components/Header";
import { Link, useNavigate } from "react-router-dom";
import { useFetchUpcoming } from "../hooks/all.hooks";

const Home: React.FC = () => {
  const navigate = useNavigate();
  const [isFullscreen, setIsFullscreen] = useState(false);

  // Using useInView for different sections
  // const { ref: heroRef, inView: heroInView } = useInView({ triggerOnce: true });
  const { ref: activitiesRef, inView: activitiesInView } = useInView({
    triggerOnce: false,
  });

  const { ref: newsRef, inView: newsInView } = useInView({
    triggerOnce: false,
  });

  // const { ref: impactRef, inView: impactInView } = useInView({
  //   triggerOnce: true,
  // });

  // Initiatives data with route links
  const initiatives = [
    {
      id: 1,
      title: "Regional Shared",
      subtitle: "Repository",
      link: "/home/shared-repositories",
      image: "/assets/images/services/baobab.jpg",
    },
    {
      id: 2,
      title: "Publishing",
      subtitle: "Service",

      link: "/home/publishing-platforms",
      image: "/assets/images/services/publishnow.jpg",
    },
    {
      id: 3,
      title: "National Multi-",
      subtitle: "tenanted Repository",

      link: "/home/open-data",
      image: "/assets/images/services/weko3.jpg",
    },
    {
      id: 4,
      title: "Persistent Identifiers",
      subtitle: "Service",
      link: "/home/pids-service",
      image: "/assets/images/services/pidslink.jpg",
    },
  ];

  const handleDocumentClick = (link: string) => {
    navigate(link);
  };

  // Sections data with route links
  const sections = [
    {
      id: 3,
      mainTitle: "Videos",
      caption: "Watch and learn",
      description: "Explore video testimonies and tutorials from experts.",
      link: "https://video.wacren.net/channel/LIBSENSE/595033",
      video:
        "https://res.cloudinary.com/duwjyvr4c/video/upload/Wcrencorrected_f7toxo.mp4",
    },
  ];

  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen); // Toggle fullscreen view
  };

  const handleVideoClick = (web: string) => {
    window.open(web, "_blank");
  };

  //useFetchUpcoming

  const { data, isLoading, isError } = useFetchUpcoming((error: any) => {
    console.log("error fetching: ", error);
    // showErrorToast({ message: error.message || 'Failed to fetch news data.' });
  });

  const upcoimingItems = data?.data || [];

  const finalSection = [...upcoimingItems, ...sections];

  return (
    <>
      <div className="mt-12 mb-6">
        <HeaderBanner />
      </div>

      <div className="container mx-auto my-14">
        <h2 className="text-center text-xxl font-semibold text-foreground uppercase mb-4">
          LIBSENSE
        </h2>
        <p className="text-sm text-foreground mt-4 mb-2">
          LIBSENSE (Library Support for Embedded NREN Services and
          E-infrastructure) is a{" "}
          <Link
            target="_blank"
            to="https://www.wacren.net"
            className="cursor-pointer font-medium text-primary"
          >
            WACREN
          </Link>
          -initiated, community-driven, pan-African programme aimed at building
          a community of practice for open science and progressing the adoption
          of open science policies, services and infrastructures in Africa.
        </p>

        <p className="text-sm text-foreground">
          Since its initiation in 2016, LIBSENSE has been strengthening open
          science in Africa by leveraging the capacities and capabilities of
          research and education networks (RENs), academic libraries and
          research communities within the continent.
        </p>

        <div className="flex items-center justify-center mt-2">
          <Link
            to="/about-us#story"
            className="hover:bg-primary-600 flex-none bg-primary px-4 py-2 text-primary-foreground"
          >
            Read more
          </Link>
        </div>
      </div>

      <div className="container mx-auto mb-14">
        <h2 className="text-center text-xxl font-semibold text-foreground uppercase mb-4">
          Open Science Services
        </h2>
        <div
          ref={activitiesRef}
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-18"
        >
          {initiatives.map((initiative, index) => (
            <motion.div
              key={initiative.id}
              initial={{ opacity: 0, y: 50 }}
              animate={activitiesInView ? { opacity: 1, y: 0 } : {}}
              transition={{
                duration: 0.8,
                ease: "easeOut",
                delay: index * 0.2,
              }}
              whileHover={{
                scale: 1.05,
                boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.2)",
                transition: { duration: 0.3, ease: "easeInOut" },
              }}
              onClick={() => handleDocumentClick(initiative.link)}
              className="flex flex-col items-center border shadow-md p-4 rounded-md cursor-pointer"
            >
              <div className="mb-4 text-center text-md font-normal text-foreground block">
                <h3>{initiative.title}</h3>
                <h3>{initiative.subtitle}</h3>
              </div>

              <div className="w-full h-full rounded-md">
                <img
                  src={initiative.image}
                  alt={`${initiative.title} initiative`}
                />
              </div>
              <div className="text-center mt-4">
                <Link to={initiative.link}>
                  <button className="hover:bg-primary-600 flex-none rounded-md bg-primary px-4 py-2.5 text-xs font-semibold text-primary-foreground">
                    Read More
                  </button>
                </Link>
              </div>
            </motion.div>
          ))}
        </div>
      </div>

      {/* News, Events, Video Section */}
      {isLoading ? (
        <p className="text-center">Loading upcoming news and events...</p>
      ) : isError ? (
        <p className="text-red-500 text-center">
          Error loading upcoming news and events
        </p>
      ) : (
        <div ref={newsRef} className="bg-card p-20 mb-14">
          <div className="container mx-auto grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-8">
            {finalSection.map((section, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 50 }}
                animate={newsInView ? { opacity: 1, y: 0 } : {}}
                transition={{
                  duration: 0.8,
                  ease: "easeOut",
                  delay: index * 0.4,
                }}
                whileHover={{
                  scale: 1.06,
                  boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.2)",
                  transition: { duration: 0.1, ease: "easeInOut" },
                }}
                className={`p-4 border shadow-md ${isFullscreen && section.video ? "lg:col-span-3 col-span-1" : ""}`} // Wide/fullscreen effect
              >
                <h2 className="text-center text-xxl font-normal text-foreground">
                  {section.mainTitle}
                </h2>

                {/* Display image or video based on the section */}
                {section.imageUrl ? (
                  <img
                    src={section.imageUrl}
                    alt={`${section.mainTitle}-thumbnail`}
                    className="w-full h-36 rounded-md mb-4"
                  />
                ) : section.video ? (
                  <div className="relative">
                    <video
                      src={section.video}
                      controls
                      // autoPlay
                      // loop
                      muted
                      className={`w-full rounded-md mb-4 ${
                        isFullscreen ? "h-screen" : "h-36"
                      }`} // Adjust height based on fullscreen state
                    ></video>
                    <button
                      onClick={toggleFullscreen}
                      className="absolute top-2 right-2 bg-black bg-opacity-50 text-white py-1 px-2 rounded"
                    >
                      {isFullscreen ? "Exit" : "Expand"}
                    </button>
                  </div>
                ) : null}

                <p className="text-sm font-semibold text-foreground mb-2">
                  {section.caption}
                </p>
                <p className="text-xs font-semibold text-muted-foreground mb-4">
                  {section.description}
                </p>
                {section.video ? (
                  <button
                    onClick={() => handleVideoClick(section.link)}
                    className="bg-primary text-primary-foreground py-2 px-6 rounded-md"
                  >
                    View all {section?.mainTitle?.toLowerCase()}
                  </button>
                ) : (
                  <Link to={section.link}>
                    <button className="bg-primary text-primary-foreground py-2 px-6 rounded-md">
                      View all {section?.mainTitle?.toLowerCase()}
                    </button>
                  </Link>
                )}
              </motion.div>
            ))}
          </div>
        </div>
      )}

      {/* <motion.div
        ref={impactRef}
        initial={{ opacity: 0, y: 50 }}
        animate={impactInView ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 0.6, ease: "easeOut" }}
        className="container mx-auto mb-14"
      >
        <h2 className="text-center text-xxl font-semibold text-foreground uppercase mb-4">
          OUR IMPACT
        </h2>
        <div className="border-t border-b border-gray-600 py-6 flex justify-between">
          <Stats
            value={4}
            label="Open science initiatives"
            animate={impactInView}
          />
          <Stats value={12} label="Countries impacted" animate={impactInView} />
          <Stats
            value={350}
            label="Institutions reached"
            suffix="+"
            animate={impactInView}
          />
          <Stats
            value={2500}
            label="Researchers supported"
            suffix="+"
            animate={impactInView}
          />
        </div>
      </motion.div> */}

      <LogoCarousel />
      <ButtonGroup />
    </>
  );
};

export default Home;
