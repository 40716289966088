import React from "react";
import { Link } from "react-router-dom";

const RegionalPage: React.FC = () => {
  return (
    <>
      <p className="text-sm text-foreground mb-4">
        LIBSENSE collaborates with regional higher education regulatory and
        oversight bodies to establish comprehensive, cross-cutting regional open
        science policies. These collaborations help to align regional policies
        with the openness, transparency, and inclusivity principles outlined in
        the{" "}
        <Link
          to="https://baobab.wacren.net/records/x7t4d-qm061"
          target="_blank"
          className="text-primary"
        >
          UNESCO Recommendations on Open Science
        </Link>
        .
      </p>
      <p className="text-sm text-foreground mb-4">
        Our regional leadership meetings in{" "}
        <Link
          to="https://event.ubuntunet.net/event/65/"
          target="_blank"
          className="text-primary"
        >
          Zambia
        </Link>
        ,{" "}
        <Link
          to="https://indico.wacren.net/event/234/"
          target="_blank"
          className="text-primary"
        >
          Abidjan
        </Link>
        , and{" "}
        <Link
          to="https://indico.wacren.net/event/195/"
          target="_blank"
          className="text-primary"
        >
          Abuja
        </Link>{" "}
        addressed open science subjects pertinent to the region. These meetings
        foster active engagement among university leaders, regulators, and
        research funders, highlight recent policy advancements, and explore
        national and international cooperation opportunities. They also promote
        leadership in research excellence and establish a sustainable research
        ecosystem.
      </p>
    </>
  );
};

export default RegionalPage;
