import React from "react";
import SharedRepositories from "../../components/SharedRepositories";
import HeaderImage from "../../components/HeaderImage";

const SharedRepo: React.FC = () => {
  const handleDocumentClick = () => {
    window.open("https://baobab.wacren.net/", "_blank");
  };

  return (
    <>
      <HeaderImage image="/assets/images/services/baobab-bg.jpg" />

      <div className="min-h-screen ">
        <SharedRepositories
          title="BAOBAB - Regional Shared Repository"
          paragraphs={[
            "BAOBAB is a digital repository platform. It provides a powerful and flexible solution for managing, preserving, and sharing research data, publications, and other digital assets. BAOBAB is designed to handle large volumes of data.",
            "It can be tailored to meet specific needs, making it an essential tool for institutions looking to create and maintain a robust digital archive.",
          ]}
          buttons={[
            {
              label: "Read More",
              onClick: handleDocumentClick,
              variant: "default",
            },
          ]}
        />
      </div>
    </>
  );
};

export default SharedRepo;
