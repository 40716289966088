import React from "react";
import { useNavigate } from "react-router-dom";

const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate("/");
  };

  return (
    <div className="flex h-screen w-full flex-col items-center justify-center bg-card text-foreground">
      <div className="text-center">
        <h1 className="text-xxxxl font-bold text-primary animate-bounce">
          404
        </h1>
        <p className="mt-4 text-lg font-semibold">Oops! Page not found.</p>
        <p className="mt-2 text-sm text-foreground/50">
          The page you’re looking for doesn’t exist or has been moved.
        </p>
        <button
          onClick={handleGoHome}
          className="mt-8 px-6 py-2 rounded-lg bg-primary text-primary-foreground font-semibold hover:bg-primary transition duration-200 ease-in-out"
        >
          Go Back Home
        </button>
      </div>
    </div>
  );
};

export default NotFoundPage;
