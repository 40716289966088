import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

interface PageTitleProps {
  title: string;
  description?: string;
  keywords?: string;
}

const PageTitle: React.FC<PageTitleProps> = ({
  title,
  description,
  keywords,
}) => {
  const location = useLocation();

  useEffect(() => {
    document.title = title;
  }, [location, title]);

  return (
    <Helmet>
      <title>{title}</title>
      {description && <meta name="description" content={description} />}
      {keywords && <meta name="keywords" content={keywords} />}
      <link rel="canonical" href={window.location.href} />
    </Helmet>
  );
};

export default PageTitle;
