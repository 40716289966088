import React from "react";
import { useParams } from "react-router-dom";
import WhatWeDo from "../../components/ReusableData";
import HeaderImage from "../../components/HeaderImage";
import Institutional from "./OPS/Institution";
import NationalPage from "./OPS/National";
import RegionalPage from "./OPS/Regional";
// import ContinentalPage from "./OPS/Continental";

const OpenPolicyPage: React.FC = () => {
  const { policyType } = useParams<{ policyType: string }>(); // Get dynamic URL part
  const sections = ["Institutional", "National", "Regional"];

  const content = {
    institutional: <Institutional />,
    national: <NationalPage />,
    regional: <RegionalPage />,
    // continental: <ContinentalPage />,
  };

  return (
    <>
      <HeaderImage image="/assets/images/backgrounds/policy_governance.jpg" />
      <WhatWeDo
        sections={sections}
        content={content}
        title="Open Science Policy & Governance"
        paragraphs={[
          <p>
            LIBSENSE adopts a two-pronged approach to fostering an open science
            paradigm and culture among African constituents. The bottom-up and
            vice versa approaches have proven effective at the institutional,
            national, and regional levels.
          </p>,
        ]}
        initialSection={policyType?.toLowerCase() || "institutional"}
        route="open-science-policy"
      />
    </>
  );
};

export default OpenPolicyPage;
