import React, { useEffect, useState } from "react";
import HeaderImage from "../components/HeaderImage";

const ContactUs: React.FC = () => {
  const [expandedFAQ, setExpandedFAQ] = useState<number | null>(null);

  useEffect(() => {
    const element = document.getElementById("contact-us");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const toggleFAQ = (index: number) => {
    setExpandedFAQ(expandedFAQ === index ? null : index);
  };

  const faq = [
    {
      question: "What is LIBSENSE ?",
      answer:
        "LIBSENSE stands for Library Support for Embedded NREN Services and E-infrastructure. It aims to bring together the research and education networks (RENs) and academic library communities to strengthen open access and open science in Africa.",
    },
    {
      question: "What are the core objectives of LIBSENSE ?",
      answer: `LIBSENSE has four main objectives:
        <ul>
        <li>1. to support cultural change</li>
        <li>2. to provide technical support</li>
        <li>3. to build capacity</li>
        <li>4. and to develop value-added services.</li>
        </ul>
        `,
    },
    {
      question: "What comprises of the LIBSENSE community ?",
      answer:
        "The LIBSENSE Community comprises African librarians, institutional libraries research and education networks, communities of practice, publishers, editors, etc.",
    },
    {
      question: "Who can join LIBSENSE ?",
      answer:
        "Any qualified librarian, institutional library, publisher, editor etc, can join LIBSENSE",
    },
    {
      question: "Any financial commitment to be made ?",
      answer:
        "For now, no financial commitment is required. However, LIBSENSE needs the support of individuals, funding bodies, and groups to undertake its ambitious activities. Your donation is welcome and would be appreciated.",
    },
    {
      question: "What is coverage of LIBSENSE ?",
      answer:
        "Even though LIBSENSE is WACREN-led, its scope and activities transcend the WACREN region to the whole of Africa. Under AC3, the projecting currently sponsoring LIBSENSE’s activities, the aim is to",
    },
    {
      question: "Who are the partners ?",
      answer: `
              <p>
          LIBSENSE partners include
          <a
            style="color: blue;"
            href="https://wacren.net/en/"
            target="_blank"
            rel="noreferrer"
          >
            WACREN
          </a>
          ,
          <a
            style="color: blue;"
            href="https://asrenorg.net/"
            target="_blank"
            rel="noreferrer"
          >
            ASREN
          </a>
          ,
          <a
            style="color: blue;"
            href="https://ubuntunet.net/"
            target="_blank"
            rel="noreferrer"
          >
            UbuntuNet Alliance
          </a>
          ,
          <a
            style="color: blue;"
            href="https://africaconnect3.net/"
            target="_blank"
            rel="noreferrer"
          >
            AfricaConnect3
          </a>
          ,
          <a
            style="color: blue;"
            href="https://www.geant.org/"
            target="_blank"
            rel="noreferrer"
          >
            GÉANT
          </a>
          ,
          <a
            style="color: blue;"
            href="https://www.eifl.net/"
            target="_blank"
            rel="noreferrer"
          >
            EIFL
          </a>
          ,
          <a
            style="color: blue;"
            href="https://www.nii.ac.jp/en/"
            target="_blank"
            rel="noreferrer"
          >
            NII
          </a>
          , and COAR. These partners collaboratively work towards achieving
          LIBSENSE's objectives through joint activities, including workshops,
          trainings, surveys, publications, advocacy, infrastructure, etc.
        </p>
      `,
    },
    {
      question: "How can I join LIBSENSE ?",
      answer: `
      <p>
      It's easy. Send an email with your profile to <a style="color: blue;" href="mailto:libsense@ren.africa" target="_blank">libsense@ren.africa</a>. The programme managers will reach out to you as soon as possible.
      </p>
      `,
    },
    {
      question: "Ask a question ?",
      answer: `
      <p>Are your questions not answered? Make more enquiries at <a style="color: blue;" href="mailto:libsense@ren.africa" target="_blank">libsense@ren.africa</a></p>
      `,
    },
  ];

  return (
    <>
      <HeaderImage image="/assets/images/backgrounds/contact.jpg" />

      <div className="container mx-auto my-14" id="contact-us">
        <h2 className="text-center text-xxl font-semibold text-foreground uppercase mb-4">
          Contact Us
        </h2>

        <section className="mb-14">
          <div className="flex items-center justify-center">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3970.3678091414695!2d-0.1743888240599749!3d5.659827194321593!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfdf9bf0c9a7d497%3A0x609219b8a4fa548e!2sWACREN!5e0!3m2!1sen!2sgh!4v1729445267417!5m2!1sen!2sgh"
              width="800"
              height="600"
              loading="lazy"
              title="WACREN office"
            ></iframe>
          </div>
          <div className="flex items-center flex-col justify-center mt-8">
            <h3 className="text-lg font-semibold text-foreground mb-4">
              Want to talk to us?
            </h3>
            <p className="text-xs text-foreground mb-2">
              Email us at{" "}
              <a
                href="mailto:libsense@ren.africa"
                target="_blank"
                rel="noreferrer"
                className="text-primary"
              >
                libsense@ren.africa
              </a>
            </p>
            <p className="text-xs text-foreground mb-2">
              Call us at{" "}
              <a
                href="tel:+447910011855"
                target="_blank"
                rel="noreferrer"
                className="text-primary"
              >
                +447910011855
              </a>
            </p>

            <p className="text-xs text-foreground mb-2">Postal Address</p>
            <ul className="text-xs text-foreground text-center">
              <li>West and Central African Research and Education Network,</li>
              <li>VCG Office Complex, IPS Road</li>
              <li>P O Box LG 1279,</li>
              <li>Accra, Ghana</li>
            </ul>
          </div>
        </section>

        {/* FAQs Section */}
        <section className="text-center mb-8">
          <h3 className="text-2xl font-bold mb-6">FAQs</h3>
          <div className="space-y-4">
            {faq.map((faq, index) => (
              <div
                key={index}
                className="bg-primary text-primary-foreground p-4 rounded-md"
              >
                <div
                  className="flex justify-between items-center cursor-pointer"
                  onClick={() => toggleFAQ(index)}
                >
                  <p className="text-sm text-primary-foreground">
                    {faq.question}
                  </p>
                  <button className="text-xl font-bold">
                    {expandedFAQ === index ? "-" : "+"}
                  </button>
                </div>
                {expandedFAQ === index && (
                  <div className="mt-4 bg-card p-4 text-left text-foreground rounded-md">
                    <p
                      className="text-sm text-foreground"
                      dangerouslySetInnerHTML={{ __html: faq.answer }}
                    ></p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </section>
      </div>
    </>
  );
};

export default ContactUs;
