import React from "react";
import WhatWeDo from "../../components/ReusableData";
import HeaderImage from "../../components/HeaderImage";
import { useParams } from "react-router-dom";
import Institutional from "./OAI/Institution";
import NationalPage from "./OAI/National";
import RegionalPage from "./OAI/Regional";

const OpenAccessPage: React.FC = () => {
  const { policyType } = useParams<{ policyType: string }>();
  const sections = ["Institutional", "National", "Regional"];

  const content = {
    institutional: <Institutional />,
    national: <NationalPage />,
    regional: <RegionalPage />,
  };

  return (
    <>
      <HeaderImage />
      <WhatWeDo
        sections={sections}
        content={content}
        title="Open Science Infrastructure and Services"
        paragraphs={[
          <p>
            LIBSENSE considers open infrastructure a key part of strengthening
            open science in Africa. Its participation in the{" "}
            <a
              target="_blank"
              href="https://www.eifl.org/eifl-in-action/collaboration-sustainable-open-access-publishing-africa"
              className="text-primary cursor-pointer"
              rel="noreferrer"
            >
              three-year Diamond Open Access Publishing in Africa project
            </a>{" "}
            demonstrates our commitment to removing barriers to the visibility
            and accessibility of African scholarship by providing open access
            services to the research community. The LIBSENSE-Connect initiative
            builds a community of practice for infrastructure development to
            support open science in Africa.
          </p>,
        ]}
        initialSection={policyType?.toLowerCase() || "institutional"}
        route="open-infrastructure"
      />
    </>
  );
};

export default OpenAccessPage;
